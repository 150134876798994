import React from 'react'
import { Link } from 'react-router-dom'
import FooterLogo from '../../assets/img/logo/logo.png'
// import Logo from '../../assets/img/logo/logo.png'
import BG1 from '../../assets/img/bg/footer_bg.png'
import SbtnIcon from '../../assets/img/bg/sbtn-icon.png'

function Main() {
    return (
        <>
            <footer className="footer-bg footer-p">
                <div className="footer-top pt-120 pb-90  p-relative"
                    style={{ backgroundColor: "#161616", backgroundImage: `url(${BG1})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }} >
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-xl-5 col-lg-5 col-sm-6">
                                <div className="footer-widget mb-30">
                                    <div className="f-widget-title mb-100">
                                        <img src={FooterLogo} alt="img" style={{opacity: 0.5}} />
                                    </div>
                                    <div className="footer-subricbe p-relative"
                                        data-animation="fadeInDown" data-delay=".4s" >
                                        <h6>Get Contact</h6>
                                        <form action="news-mail.php"
                                            method="post" className="contact-form " >
                                            <button className="btn">
                                                <img src={SbtnIcon} alt="img" />
                                            </button>
                                            <input type="text" id="email2"
                                                name="email2" className="header-input" placeholder="admin@vachilabs.com" required="" />
                                        </form>
                                    </div>
                                    <div className="info">
                                        <ul>
                                            <li>
                                                <span className="red">P:</span> +91 9039004977 / 9039004976
                                            </li>
                                            <li>
                                                <span className="red">L:</span> Bhopal (M.P.)
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-sm-6">
                                <div className="footer-widget mb-30">
                                    <div className="f-widget-title">
                                        <h2>Follow Me</h2>
                                    </div>
                                    <div className="footer-link">
                                        <ul>
                                            <li>
                                                <Link to="https://www.facebook.com/profile.php?id=61556095646032" target='_blank'>Facebook</Link>
                                            </li>
                                            <li>
                                                <Link to="https://twitter.com/vaacilabs2103" target='_blank'>Twitter</Link>
                                            </li>
                                            <li>
                                                <Link to="https://www.instagram.com/vachilabs_pvtltd/" target='_blank'>Instagram</Link>
                                            </li>
                                            <li>
                                                <Link to="https://www.linkedin.com/in/vachilabs-innovations-private-limited-3976b42ab/" target='_blank'>LinkedIn</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-sm-6">
                                <div className="footer-widget mb-30">
                                    <div className="f-widget-title">
                                        <h2>Top Links</h2>
                                    </div>
                                    <div className="footer-link">
                                        <ul>
                                            <li>
                                                <Link to="/aboutus">About</Link>
                                            </li>
                                            {/* <li>
                                                <Link to="#">Careers</Link>
                                            </li>
                                            <li>
                                                <Link to="#">Terms of service</Link>
                                            </li> */}
                                            <li>
                                                <Link to="/jobdetails">We’re Hiring</Link>
                                            </li>
                                            {/* <li>
                                                <Link to="#">Cookie Policy</Link>
                                            </li>
                                            <li>
                                                <Link to="#"> News &amp; Media</Link>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-sm-6">
                                <div className="footer-widget mb-30">
                                    <div className="f-widget-title">
                                        <h2>Explore</h2>
                                    </div>
                                    <div className="footer-link">
                                        <ul>
                                            <li>
                                                <Link to="/contact"> Contact Us</Link>
                                            </li>
                                            {/* <li>
                                                <Link to="#">Team Member</Link>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="copyright-wrap">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                Copyright © 2023 <Link to="#">Zucbedesign</Link>. All rights reserved.
                            </div>
                            <div className="col-lg-6 text-right">
                                <img src={Logo} alt="img" width='75px' height='60px' style={{opacity: 0.4}}/>
                            </div>
                        </div>
                    </div>
                </div> */}
            </footer>

        </>
    )
}

export default Main
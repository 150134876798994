import React from 'react'
// import Modal from 'react-modal';
// import { Link } from 'react-router-dom'
// import Details01 from '../../assets/img/blog/b_details01.jpg'
// import Details02 from '../../assets/img/blog/b_details02.jpg'
// import Icon01 from '../../assets/img/icon/c_d01.png'
// import Details03 from '../../assets/img/blog/b_details03.jpg'
// import Details04 from '../../assets/img/blog/b_details04.jpg'
// import Avtar from '../../assets/img/blog/comment/avatar.png'
// import Icon02 from '../../assets/img/blog/comment/c_01.png'
// import Icon03 from '../../assets/img/blog/comment/c_02.png'
// import Icon04 from '../../assets/img/blog/comment/c_03.png'
import JOB_DETAILS from "./jobdetails"
import SbtnIcon from '../../assets/img/bg/sbtn-icon.png'
// import FileUploadModal from "./resumeUpload"

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';



function ScrollToTopOnPageChange() {
    const { pathname } = useLocation();
  
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
}

function Innerdtls() {
    const handleSubmit = async (e) => {
        e.preventDefault();
        var formData = new FormData(document.getElementById('applied-job-form'));

        console.log(formData)
        var jsonData = {};
        formData.forEach(function(value, key){
            jsonData[key] = value;
        });
        console.log(jsonData)
        jsonData['heading'] = "Apply for job"
        jsonData['subject'] = "Apply for job"
        if (jsonData['email_id'] !== "" && jsonData['mobile_number'] !== "") {
            const response = await fetch("https://o92wy0s3sl.execute-api.ap-south-1.amazonaws.com/sendemail", {
                mode: "no-cors",
                method : "POST",
                header : {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(jsonData)
                
            })
            console.log("response >>>>>>  >>>", response)
            if (response.status === 0) {
                alert("Thank you to contact us. We will connect with you shortly.")
                window.location.reload();
            }
        } else {
            alert("Please enter mobile and contact number to apply job")
        }

    }
  
    return (
      <>
        <ScrollToTopOnPageChange />
        <section className="inner-blog pt-120 pb-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                {Object.keys(JOB_DETAILS).map((key, ind) => (
                  <div className="blog-details-wrap" key={key}>
                    <div className="details__content pb-30">
                      <h2>{JOB_DETAILS[key]['job_title']}</h2>
                      <div className="meta-info">
                        <ul>
                          <li>
                            <i className="fal fa-eye" /> 100 Views
                          </li>
                          <li>
                            <i className="fal fa-location" /> {JOB_DETAILS[key]['location']}
                          </li>
                          <li>
                            <i className="fal fa-calendar-alt" /> 24th March 2024
                          </li>
                        </ul>
                      </div>
                      <p>
                        <b>Summary: </b>
                        {JOB_DETAILS[key]['summary']} <br /> <br />
                        <b>Salary: </b>
                        {JOB_DETAILS[key]['salary']}
                      </p>
                      <p>
                        <b>Responsibilities: </b>
                        <br />
                        <ul>
                          {JOB_DETAILS[key]['Responsibilities'].map((resp, i) => (
                            <li key={i}>-- {resp}</li>
                          ))}
                        </ul>
                      </p>
                      <blockquote>
                        <footer>By {JOB_DETAILS[key]['quote_by']}</footer>
                        <h3>{JOB_DETAILS[key]['quote']}</h3>
                      </blockquote>
                      <p>
                        <b>Qualification: </b>
                        <br />
                        <ul>
                          {JOB_DETAILS[key]['Qualifications'].map((qual, i) => (
                            <li key={i}>-- {qual}</li>
                          ))}
                        </ul>
                      </p>
                      <form  id="applied-job-form" className="contact-form " >
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="contact-field p-relative c-name mb-30">
                                    <input type="text" id="email_id" name="email_id" className="header-input" placeholder="Enter your email address." required/>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="contact-field p-relative c-name mb-30">
                                    <input type="text" id="mobile_number" name="mobile_number" className="header-input" placeholder="Enter your mobile number" required/>
                                </div>
                            </div>
                            <input type="hidden" name="jobTitle" value={JOB_DETAILS[key]['job_title']} />
                            <input type="hidden" name="jobId" value={JOB_DETAILS[key]['job_id']} />
                            <div className="col-lg-2">
                                <button className="btn" onClick={handleSubmit} >
                                    <img src={SbtnIcon} alt="img" />
                                </button>
                            </div>
                            
                        </div>
                     </form>
                      {/* <button onClick={() => openModal(JOB_DETAILS[key]['job_id'])}>
                        Apply job
                      </button>
                      <FileUploadModal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        jobId={selectedJobId}
                      /> */}
                    </div>
                    <hr />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
  

export default Innerdtls
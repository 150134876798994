import React from 'react'
import { Link } from 'react-router-dom'
import Icon1 from '../../assets/img/icon/c-icon1.png'
import Icon2 from '../../assets/img/icon/c-icon2.png'
import Icon3 from '../../assets/img/icon/c-icon3.png'
import Icon4 from '../../assets/img/icon/c-icon4.png'

function Area() {
    const handleSubmit = async (e) => {
        e.preventDefault();
        var formData = new FormData(document.getElementById('contactusForm'));

        // Convert form data to JSON object
        var jsonData = {};
        formData.forEach(function(value, key){
            jsonData[key] = value;
        });
        console.log(jsonData)
        jsonData['heading'] = "A new enquery"
        const response = await fetch("https://o92wy0s3sl.execute-api.ap-south-1.amazonaws.com/sendemail", {
            mode: "no-cors",
            method : "POST",
            header : {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(jsonData)
            
        })
        console.log("response >>>>>>  >>>", response)
        if (response.status === 0) {
            alert("Thank you to contact us. We will connect with you shortly.")
        }
        
    }

    return (
        <>
            <section id="contact" className="contact-area after-none contact-bg pt-120 pb-120 p-relative" >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="contact-bg02">
                                <div className="section-title mb-50">
                                    <h2>Get in touch with Us</h2>
                                </div>
                                {/* <form action="mail.php" method="post" className="contact-form mt-30"> */}
                                <form id="contactusForm" className="contact-form mt-30">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="contact-field p-relative c-name mb-30">
                                                <input type="text" id="firstn" name="firstname" placeholder="First Name" required/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="contact-field p-relative c-email mb-20">
                                                <input type="text" id="lastn" name="lastname" placeholder="Last Name" required/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="contact-field p-relative c-subject mb-30">
                                                <input type="text" id="email" name="email" placeholder="Email" required/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="contact-field p-relative c-subject mb-30">
                                                <input type="text" id="phone" name="phone" placeholder="Phone No." required/>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-subject mb-30">
                                                <input type="text" id="subject" name="subject" placeholder="Subject" required/>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-message mb-30">
                                                <textarea name="message" id="message" cols={30} rows={10} placeholder="Write comments" defaultValue={""} />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <p>
                                     
                                                * Call us 24/7 or fill out the form above to receive a free consulation.
                                            </p>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="slider-btn  text-center">
                                                <button className="btn ss-btn active" data-animation="fadeInRight" data-delay=".8s" onClick={handleSubmit} >
                                                    Send Message<i className="fa-sharp fa-light fa-arrow-up"/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="contact-info">
                                <ul>
                                    <li>
                                        <div className="single-cta">
                                            <div className="f-cta-icon">
                                                <img src={Icon1} alt="logo" />
                                            </div>
                                            <div className="text">
                                                <p>Contact Phone</p>
                                                <h5>+91 9039004977</h5>
                                                <h5>+91 9039004976</h5>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="single-cta">
                                            <div className="f-cta-icon">
                                                <img src={Icon2} alt="logo" />
                                            </div>
                                            <div className="text">
                                                <p>Contact Location</p>
                                                <h5>Bhopal (M.P.)</h5>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="single-cta">
                                            <div className="f-cta-icon">
                                                <img src={Icon3} alt="logo" />
                                            </div>
                                            <div className="text">
                                                <p>Contact Email</p>
                                                <h5>admin@vachilabs.com, sales@vachilabs.com</h5>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="single-cta">
                                            <div className="f-cta-icon">
                                                <img src={Icon4} alt="logo" />
                                            </div>
                                            <div className="text">
                                                <p>Office Time</p>
                                                <h5>Office open 10AM - 18PM</h5>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="footer-social">
                                            <Link to="https://www.facebook.com/" target='_blank'>
                                                <i className="fab fa-facebook-f" />
                                            </Link>
                                            <Link to="https://www.instagram.com/" target='_blank'>
                                                <i className="fab fa-instagram" />
                                            </Link>
                                            <Link to="https://twitter.com/" target='_blank'>
                                                <i className="fab fa-twitter" />
                                            </Link>
                                            <Link to="https://in.pinterest.com/" target='_blank'>
                                                <i className="fab fa-pinterest" />
                                            </Link>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Area
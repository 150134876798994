import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../assets/img/logo/logo.png'
import Toogelmenu from '../../assets/img/bg/toggle-menu.png'

function Main() {

    const [Nav, setNav] = useState(false)
    const [scroll, setScroll] = useState(false);
    const [mobile, setMobile] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setScroll(true);
        } else {
            setScroll(false);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            const breakpoint = 1024; 

            if (window.innerWidth <= breakpoint) {
                setMobile(true);
            } else {
                setMobile(false);
            }
        };

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
      };
      const closeMenu = () => {
        setMobileMenuOpen(false);
      };
    
      const handleMenuItemClick = () => {
        closeMenu();
        setIsDropdownOpen(!isDropdownOpen);
      };



    return (
        <>
            {!mobile && (
                <header className="header-area header">
                    <div id="header-sticky" className={`menu-area pl-100 pr-100 ${scroll ? 'sticky-menu' : ''}`}>
                        <div className="container-fluid">
                            <div className="second-menu">
                                <div className="row align-items-center">
                                    <div className="col-xl-2 col-lg-2">
                                        <div className="logo">
                                            <Link to="/">
                                                <img src={Logo} alt="logo" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-6">
                                        <div className="main-menu">
                                            <nav id="mobile-menu">
                                                <ul>
                                                    <li className="has-sub">
                                                        <Link to="/">Home</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/service">Services</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/aboutus">About Us</Link>
                                                    </li>
                                                    <li>
                                                                <Link to="/pricing">Pricing</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/contact">Contact</Link>
                                                    </li>
                                                    {/* <li className="has-sub">
                                                        <Link to="#">Pages</Link>
                                                        <ul>
                                                            <li>
                                                                <Link to="/service">Services</Link>
                                                            </li>
                                                            <li>
                                                                {" "}
                                                                <Link to="/servicedetails">Services Details</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/projects">Project</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/team">Our Team</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/teamdetails">Team Details</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/pricing">Pricing</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/faq">Faq</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/shop">Shop</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/shopdetails">Shop Details</Link>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li className="has-sub">
                                                        <Link to="/blog">Blog</Link>
                                                        <ul>
                                                            <li>
                                                                <Link to="/blog">Blog</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/blogdetails">Blog Details</Link>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <Link to="/contact">Contact</Link>
                                                    </li> */}
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 text-right d-none d-lg-block text-right text-xl-right">
                                        <div className="header-cta">
                                            <ul>
                                                <li>
                                                    <div className="call-box">
                                                        <div className="text">
                                                            M: +91 9039004975
                                                            <br />M: +91 9039004976
                                                            <br />M: +91 9039004977
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="call-box">
                                                        <div className="text">
                                                            E: admin@vachilabs.com 
                                                            <br /> E: operations@vachilabs.com
                                                            <br /> E: sales@vachilabs.com
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="call-box">
                                                        <Link to="#" className="menu-tigger" onClick={() => setNav(true)}>
                                                            <img src={Toogelmenu} alt="logo" />
                                                        </Link>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="mobile-menu">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            )}

            {Nav &&
                <div className={`offcanvas-menu ${Nav && "active"}`}>
                    <span className="menu-close" onClick={() => setNav(false)}>
                        <i className="fas fa-times" />
                    </span>
                    <form role="search" method="get" id="searchform" className="searchform"
                        action="http://wordpress.zcube.in/xconsulta/" >
                        <input type="text" name="s" id="search" placeholder="Search" />
                        <button>
                            <i className="fa fa-search" />
                        </button>
                    </form>
                    <div id="cssmenu3" className="menu-one-page-menu-container">
                        <ul className="menu">
                            <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                <Link to="/">Home</Link>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                <Link to="/service">Services</Link>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                <Link to="/pricing">Pricing</Link>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                <Link to="/aboutus">About Us</Link>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                <Link to="/contact">Contact us</Link>
                            </li>
                        </ul>
                    </div>
                    <div id="cssmenu2" className="menu-one-page-menu-container">
                        <ul id="menu-one-page-menu-12" className="menu">
                            <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                <Link to="#home">
                                    <span>+91 9039004975</span><br/>
                                    <span>+91 9039004976</span><br/>
                                    <span>+91 9039004977</span><br/>
                                    
                                </Link>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                <Link to="#howitwork">
                                    <span>admin@vachilabs.com</span><br/>
                                    <span>operations@vachilabs.com</span><br/>
                                    <span>sales@vachilabs.com</span><br/>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            }

            {mobile && (
                <header className={`header-area header ${scroll ? 'sticky-menu' : ''}`}>
                    <div id="header-sticky" className="menu-area pl-100 pr-100">
                        <div className="container-fluid">
                            <div className="second-menu">
                                <div className="row align-items-center">
                                    <div className="col-xl-2 col-lg-2">
                                        <div className="logo">
                                            <Link to="/">
                                                <img src={Logo} alt="logo" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-6">
                                        <div className="main-menu">
                                            <div className="mean-push" />
                                            <nav id="mobile-menu" style={{ display: "none" }}>
                                                <ul>
                                                    <li className="has-sub">
                                                        <Link to="/">Home</Link>
                                                        <ul>
                                                            <li>
                                                                <Link to="/">Home Page 01</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/homepage2">Home Page 02</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/homepage3">Home Page 03</Link>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <Link to="/aboutus">About Us</Link>
                                                    </li>
                                                    <li className="has-sub">
                                                        <Link to="#">Pages</Link>
                                                        <ul>
                                                            <li>
                                                                <Link to="/service">Services</Link>
                                                            </li>
                                                            <li>
                                                                {" "}
                                                                <Link to="/servicedetails">Services Details</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/projects">Project</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/team">Our Team</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/teamdetails">Team Details</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/pricing">Pricing</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/faq">Faq</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/shop">Shop</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/shopdetails">Shop Details</Link>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li className="has-sub">
                                                        <Link to="/blog">Blog</Link>
                                                        <ul>
                                                            <li>
                                                                <Link to="/blog">Blog</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/blogdetails">Blog Details</Link>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <Link to="/contact">Contact</Link>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 text-right d-none d-lg-block text-right text-xl-right">
                                        <div className="header-cta">
                                            <ul>
                                                <li>
                                                    <div className="call-box">
                                                        <div className="sc">
                                                            <Link to="#">Fb.</Link>
                                                            <Link to="#">Ln.</Link>
                                                            <br />
                                                            <Link to="#">Tw.</Link>
                                                            <Link to="#">Be.</Link>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="call-box">
                                                        <div className="text">
                                                            Best Solution For Business
                                                            <br /> Consulting Service
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="call-box">
                                                        <div className="text">
                                                            P: +91 9039004977 / 9039004976
                                                            <br /> E: admin@vachilabs.com, sales@vachilabs.com
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="call-box">
                                                        <Link to="#" className="menu-tigger">
                                                            <img src={Toogelmenu} alt="logo" />
                                                        </Link>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="mobile-menu mean-container">
                                            <div className="mean-bar">
                                                <Link to="#nav" onClick={toggleMobileMenu}
                                                    className={`meanmenu-reveal ${mobileMenuOpen ? 'meanclose' : ''}`}
                                                    style={{ right: 0, left: "auto", textAlign: "center", textIndent: 0, fontSize: 18 }}>
                                                   {mobileMenuOpen ? 'X' : <span><span><span></span></span></span>}
                                                </Link>
                                                <nav className="mean-nav"> 
                                                <ul style={{ display: mobileMenuOpen ? 'block' : 'none' }}>
                                                        <li className={`has-sub ${handleMenuItemClick ? 'open' : ''}`}> 
                                                            <Link to="/" onClick={handleMenuItemClick}>Home</Link>
                                                            <ul style={{ display: "none" }}>
                                                                <li>
                                                                    <Link to="/">Home Page 01</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/homepage2">Home Page 02</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/homepage3">Home Page 03</Link>
                                                                </li>
                                                            </ul>
                                                            <Link className="mean-expand" to="#" style={{ fontSize: 18 }} >
                                                                +
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/aboutus">About Us</Link>
                                                        </li>
                                                        <li className="has-sub">
                                                            <Link to="#">Pages</Link>
                                                            <ul style={{ display: "none" }}>
                                                                <li>
                                                                    <Link to="/service">Services</Link>
                                                                </li>
                                                                <li>
                                                                    {" "}
                                                                    <Link to="/servicedetails">Services Details</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/projects">Project</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/team">Our Team</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/teamdetails">Team Details</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/pricing">Pricing</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/faq">Faq</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/shop">Shop</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/shopdetails">Shop Details</Link>
                                                                </li>
                                                            </ul>
                                                            <Link className="mean-expand" to="#" style={{ fontSize: 18 }} >
                                                                +
                                                            </Link>
                                                        </li>
                                                        <li className="has-sub">
                                                            <Link to="/blog">Blog</Link>
                                                            <ul style={{ display: "none" }}>
                                                                <li>
                                                                    <Link to="/blog">Blog</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/blogdetails">Blog Details</Link>
                                                                </li>
                                                            </ul>
                                                            <Link className="mean-expand" to="#" style={{ fontSize: 18 }} >
                                                                +
                                                            </Link>
                                                        </li>
                                                        <li className="mean-last">
                                                            <Link to="/contact">Contact</Link>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            )
            }
        </>
    )
}

export default Main
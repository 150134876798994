import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Abtservice from '../MenService/Abtservice'
import Brands from '../MenService/Brands'
// import Video from '../MenHomePage1/Video'
import Area from '../MenHomePage1/Area'
import Testimonial from '../MenHomePage1/Testimonial'
import Cta from '../MenHomePage1/Cta'
import Footer from '../Footer/Main'

function Main() {
    return (
        <>
            <Header />
            <main>
                <Breadcrumb mainheading={"Service"} sub={"Home"} title={"Service"} />
                <Abtservice />
                {/* <Brands /> */}
                {/* <Video /> */}
                <Area />
                {/* <Testimonial /> */}
                <Cta />
            </main>
            <Footer />
        </>
    )
}

export default Main
import React from 'react';
import { Link } from 'react-router-dom';
import Shop1 from '../../assets/img/shop/img1.jpg'
import Shop2 from '../../assets/img/shop/img2.jpg'
import Shop3 from '../../assets/img/shop/img3.jpg'
import Shop4 from '../../assets/img/shop/img4.jpg'
import Shop5 from '../../assets/img/shop/img5.jpg'
import Shop6 from '../../assets/img/shop/img6.jpg'
import Shop7 from '../../assets/img/shop/img7.jpg'
import Shop8 from '../../assets/img/shop/img8.jpg'
import Shop9 from '../../assets/img/shop/img9.jpg'

const products = [
    {
        id: 1,
        name: 'Jasper Morrison Cork Stool',
        image: Shop1,
        price: '$475.22',
    },
    {
        id: 2,
        name: 'Girona Chair',
        image: Shop2,
        price: '$490.51',
    },
    {
        id: 3,
        name: 'Saya Chair',
        image: Shop6,
        price: '$630.44',
    },
    {
        id: 4,
        name: 'Arco Dining Table',
        image: Shop7,
        price: '$219.78',
    },
    {
        id: 5,
        name: 'Eave Modular Sofa',
        image: Shop8,
        price: '$854.08',
    },
    {
        id: 6,
        name: 'D1 Desk',
        image: Shop9,
        price: '$446.61',
    },
    {
        id: 7,
        name: 'Farnsworth House Desk',
        image: Shop4,
        price: '$202.87',
    },
    {
        id: 8,
        name: 'Hopper Chair',
        image: Shop5,
        price: '$169.43',
    },
    {
        id: 9,
        name: 'Boba Pendant Lamp',
        image: Shop3,
        price: '$95.00',
        oldPrice: '$778.35',
    },
];

function Area() {
    return (
        <section className="shop-area pt-120 pb-120 p-relative" data-animation="fadeInUp animated" data-delay=".2s">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                        <div className="row">
                            <div className="col-lg-6 col-sm-6">
                                <h6 className="mt-20 mb-30">Showing 1–9 of 10 results</h6>
                            </div>
                            <div className="col-lg-6 col-sm-6 text-right">
                                <select name="orderby" className="orderby" aria-label="Shop order" defaultValue="menu_order">
                                    <option value="menu_order">Default sorting</option>
                                    <option value="popularity">Sort by popularity</option>
                                    <option value="rating">Sort by average rating</option>
                                    <option value="date">Sort by latest</option>
                                    <option value="price">Sort by price: low to high</option>
                                    <option value="price-desc">Sort by price: high to low</option>
                                </select>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            {products.map((product) => (
                                <div className="col-lg-4 col-md-6" key={product.id}>
                                    <div className="product mb-40">
                                        <div className="product__img">
                                            <Link to="/shopdetails">
                                                <img src={product.image} alt={product.name} />
                                            </Link>
                                            <div className="product-action text-center">
                                                <Link to="/shopdetails">Add Cart</Link>
                                            </div>
                                        </div>
                                        <div className="product__content pt-30">
                                            <h4 className="pro-title">
                                                <Link to="/shopdetails">{product.name}</Link>
                                            </h4>
                                            <div className="price">
                                                <span>{product.price}</span>
                                                {product.oldPrice && <span className="old-price">{product.oldPrice}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="pagination-wrap text-center">
                                    <nav>
                                        <ul className="pagination">
                                            <li className="page-item">
                                                <Link to="#">
                                                    {" "}
                                                    <i className="fal fa-long-arrow-left" />
                                                </Link>
                                            </li>
                                            <li className="page-item active">
                                                <Link to="#">1</Link>
                                            </li>
                                            <li className="page-item">
                                                <Link to="#">2</Link>
                                            </li>
                                            <li className="page-item">
                                                <Link to="#">3</Link>
                                            </li>
                                            <li className="page-item">
                                                <Link to="#">...</Link>
                                            </li>
                                            <li className="page-item">
                                                <Link to="#">10</Link>
                                            </li>
                                            <li className="page-item">
                                                <Link to="#">
                                                    {" "}
                                                    <i className="fal fa-long-arrow-right" />
                                                </Link>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Area;

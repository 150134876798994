import React, { useState } from 'react'
import Faqimg from '../../assets/img/bg/faq-img2.png'
import Sublogo from '../../assets/img/logo/logo.png'
import Bg1 from '../../assets/img/bg/faq-bg.png'

function Faqarea() {

    const [activeSection, setActiveSection] = useState(0);

    const handleToggleSection = (sectionIndex) => {
        if (activeSection === sectionIndex) {
            setActiveSection(null);
        } else {
            setActiveSection(sectionIndex);
        }
    };
    const faqData = [
        {
            question: 'What services does your IT company offer?',
            answer: 'We offer a comprehensive suite of IT services, including: Website design and development, Digital marketing services, Lead generation solutions, ERP (Enterprise Resource Planning) consulting',
        },
        {
            question: 'How can I get started with your website design services?',
            answer: 'To get started with our website design services, simply reach out to us through our website, phone, or email. Our team will discuss your requirements and guide you through the process.',
        },
        {
            question: 'What digital marketing services do you provide?',
            answer: 'Our digital marketing services cover a wide range of strategies, including search engine optimization (SEO), social media marketing, content marketing, and online advertising. We tailor our approach to meet your business goals and target audience.',
        },
        {
            question: 'How does your lead generation process work?',
            answer: 'Our lead generation process involves a combination of targeted marketing campaigns, strategic content creation, and data analysis. We aim to attract and nurture leads that are likely to convert into valuable customers for your business.',
        },
        {
            question: 'Can you explain the benefits of ERP consulting for my business?',
            answer: 'ERP consulting streamlines and integrates various business processes, enhancing efficiency and decision-making. It helps in optimizing resource utilization, improving data accuracy, and providing a unified view of business operations.',
        },
        {
            question: 'Do you work with specific industries for ERP consulting?',
            answer: 'Yes, our ERP consulting services are adaptable and suitable for a variety of industries. We customize our approach to meet the specific needs and challenges of your industry.',
        },
        {
            question: 'How long does it take to design and launch a website?',
            answer: 'The timeline for website design and launch varies based on the complexity and features required. We will provide you with a detailed project timeline during our initial discussions, keeping you informed at every stage.',
        },
        {
            question: 'Can you handle ongoing maintenance and updates for my website?',
            answer: 'Absolutely. We offer ongoing website maintenance and update services to ensure that your site remains secure, up-to-date, and aligned with the latest industry standards.',
        },

        {
            question: 'How do you measure the success of a digital marketing campaign?',
            answer: 'We use a combination of key performance indicators (KPIs) such as website traffic, conversion rates, and return on investment (ROI) to measure the success of a digital marketing campaign. We provide regular reports to keep you informed of the campaigns performance.',
        },
        {
            question: 'What sets your company apart in the website design and digital marketing industry?',
            answer: 'Our company stands out through a combination of creativity, technical expertise, and a results-driven approach. We prioritize client satisfaction and work collaboratively to achieve business objectives through innovative digital solutions.',
        },



    ];


    return (
        <>
            <section className="event event03 pt-120 pb-120 p-relative fix"
                style={{ backgroundImage: `url(${Bg1})`, backgroundPosition: "center center", backgroundRepeat: "no-repeat" }} >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6  wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                            <div className="about-title second-title pb-25">
                                <h5>
                                    <span className="line">
                                        {" "}
                                        <img src={Sublogo} alt="circle_right" />
                                    </span>{" "}
                                    Frequency &amp; questions
                                </h5>
                                <h2>Frequency &amp; questions Our creative agency.</h2>
                            </div>
                            <div className="faq-wrap pr-30 wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                                <div className="accordion" id="accordionExample">
                                    {faqData.map((faq, index) => (
                                        <div className="card" key={index}>
                                            <div className="card-header" id={`heading${index}`}>
                                                <h2 className="mb-0">
                                                    <button className={`faq-btn ${activeSection === index ? '' : 'collapsed'}`} type="button"
                                                        onClick={() => handleToggleSection(index)} >
                                                        {faq.question}
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id={`collapse${index}`} className={`collapse ${activeSection === index ? 'show' : ''}`}
                                                data-bs-parent="#accordionExample" >
                                                <div className="card-body">{faq.answer}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6  wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                            <img src={Faqimg} alt="circle_right" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Faqarea
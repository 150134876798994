import React from 'react'
import { Link } from 'react-router-dom'
import Team1 from '../../assets/img/team/team01.jpg'
import Team2 from '../../assets/img/team/team02.jpg'
import Team3 from '../../assets/img/team/team03.jpg'
import Team4 from '../../assets/img/team/team04.jpg'
import Team5 from '../../assets/img/team/team05.jpg'
import Team6 from '../../assets/img/team/team06.jpg'

function Member() {
    return (
        <>
            <section id="team" className="team-area fix p-relative pt-120 pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-6">
                            <div className="single-team mb-30">
                                <div className="team-thumb">
                                    <div className="brd">
                                        <img src={Team1} alt="img" />
                                    </div>
                                    <div className="team-info">
                                        <h4>
                                            <Link to="/teamdetails">H.Alexander Anna</Link>
                                        </h4>
                                        <p>Managing Director</p>
                                        <div className="team-social">
                                            <Link to="#" className="share-alt">
                                                <i className="fal fa-share-alt" />
                                            </Link>
                                            <ul>
                                                <li>
                                                    <Link to="#">
                                                        <i className="fa-brands fa-pinterest-p" />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="fab fa-instagram" />
                                                    </Link>
                                                </li>
                                                <li>
                                                    {" "}
                                                    <Link to="#">
                                                        <i className="fab fa-twitter" />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="fab fa-facebook-f" />
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6">
                            <div className="single-team mb-30">
                                <div className="team-thumb">
                                    <div className="brd">
                                        <img src={Team2} alt="img" />
                                    </div>
                                    <div className="team-info">
                                        <h4>
                                            <Link to="/teamdetails">Elizabeth Joseph</Link>
                                        </h4>
                                        <p>Managing Director</p>
                                        <div className="team-social">
                                            <Link to="#" className="share-alt">
                                                <i className="fal fa-share-alt" />
                                            </Link>
                                            <ul>
                                                <li>
                                                    <Link to="#">
                                                        <i className="fa-brands fa-pinterest-p" />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="fab fa-instagram" />
                                                    </Link>
                                                </li>
                                                <li>
                                                    {" "}
                                                    <Link to="#">
                                                        <i className="fab fa-twitter" />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="fab fa-facebook-f" />
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6">
                            <div className="single-team mb-30">
                                <div className="team-thumb">
                                    <div className="brd">
                                        <img src={Team3} alt="img" />
                                    </div>
                                    <div className="team-info">
                                        <h4>
                                            <Link to="/teamdetails">Benjamin Evelyn</Link>
                                        </h4>
                                        <p>Designer</p>
                                        <div className="team-social">
                                            <Link to="#" className="share-alt">
                                                <i className="fal fa-share-alt" />
                                            </Link>
                                            <ul>
                                                <li>
                                                    <Link to="#">
                                                        <i className="fa-brands fa-pinterest-p" />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="fab fa-instagram" />
                                                    </Link>
                                                </li>
                                                <li>
                                                    {" "}
                                                    <Link to="#">
                                                        <i className="fab fa-twitter" />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="fab fa-facebook-f" />
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6">
                            <div className="single-team mb-30">
                                <div className="team-thumb">
                                    <div className="brd">
                                        <img src={Team4} alt="img" />
                                    </div>
                                    <div className="team-info">
                                        <h4>
                                            <Link to="/teamdetails">Jack Martin</Link>
                                        </h4>
                                        <p>Designer</p>
                                        <div className="team-social">
                                            <Link to="#" className="share-alt">
                                                <i className="fal fa-share-alt" />
                                            </Link>
                                            <ul>
                                                <li>
                                                    <Link to="#">
                                                        <i className="fa-brands fa-pinterest-p" />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="fab fa-instagram" />
                                                    </Link>
                                                </li>
                                                <li>
                                                    {" "}
                                                    <Link to="#">
                                                        <i className="fab fa-twitter" />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="fab fa-facebook-f" />
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6">
                            <div className="single-team mb-30">
                                <div className="team-thumb">
                                    <div className="brd">
                                        <img src={Team5} alt="img" />
                                    </div>
                                    <div className="team-info">
                                        <h4>
                                            <Link to="/teamdetails">Kristal Leo</Link>
                                        </h4>
                                        <p>Designer</p>
                                        <div className="team-social">
                                            <Link to="#" className="share-alt">
                                                <i className="fal fa-share-alt" />
                                            </Link>
                                            <ul>
                                                <li>
                                                    <Link to="#">
                                                        <i className="fa-brands fa-pinterest-p" />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="fab fa-instagram" />
                                                    </Link>
                                                </li>
                                                <li>
                                                    {" "}
                                                    <Link to="#">
                                                        <i className="fab fa-twitter" />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="fab fa-facebook-f" />
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6">
                            <div className="single-team mb-30">
                                <div className="team-thumb">
                                    <div className="brd">
                                        <img src={Team6} alt="img" />
                                    </div>
                                    <div className="team-info">
                                        <h4>
                                            <Link to="/teamdetails">Kristal Leo</Link>
                                        </h4>
                                        <p>Designer</p>
                                        <div className="team-social">
                                            <Link to="#" className="share-alt">
                                                <i className="fal fa-share-alt" />
                                            </Link>
                                            <ul>
                                                <li>
                                                    <Link to="#">
                                                        <i className="fa-brands fa-pinterest-p" />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="fab fa-instagram" />
                                                    </Link>
                                                </li>
                                                <li>
                                                    {" "}
                                                    <Link to="#">
                                                        <i className="fab fa-twitter" />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="fab fa-facebook-f" />
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Member
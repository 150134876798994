import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Innerdtls from '../MenBlogDetails/Innerdtls'
import Footer from '../Footer/Main'

function Main() {
    return (
        <>
            <Header />
            <main>
                <Breadcrumb mainheading={"Blog Details"} sub={"Home"} title={"Blog Details"} />
                <Innerdtls />
            </main>
            <Footer />
        </>
    )
}

export default Main
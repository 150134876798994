import React from 'react'
import { Route, Routes, } from 'react-router-dom';
// import HomePageOne from '../components/HomePageOne/Main'
import HomePageTwo from '../components/HomePageTwo/Main'
// import HomePageThree from '../components/HomePageThree/Main'
import AboutUs from '../components/AboutUs/Main'
import Service from '../components/service/Main'
import ServiceDeatils from '../components/ServiceDetails/Main'
import Project from '../components/Project/Main'
import Team from '../components/Team/Main'
import TeamDetails from '../components/TeamDetails/Main'
import Pricing from '../components/Pricing/Main'
import Faq from '../components/Faq/Main'
import Shop from '../components/Shop/Main'
import ShopDetails from '../components/ShopDetails/Main'
import Blog from '../components/Blog/Main'
import BlogDetails from '../components/BlogDetails/Main'
import Contact from '../components/Contact/Main'
import Portfolio from '../components/Portfolio/Main'
import JobDetails from '../components/JobDetails/Main'

function Index() {

  return (
    <>
      <Routes>
        {/* <Route path="/homepage2" element={<HomePageOne />} /> */}
        <Route path="/" element={<HomePageTwo />} />
        {/* <Route path="/homepage3" element={<HomePageThree />} /> */}
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/service" element={<Service />} />
        <Route path="/servicedetails/:service_id" element={<ServiceDeatils />} />
        <Route path='/projects' element={<Project />} />
        <Route path='/team' element={<Team />} />
        <Route path='/teamdetails' element={<TeamDetails />} />
        <Route path='/pricing' element={<Pricing />} />
        <Route path='/faq' element={<Faq />} />
        <Route path='/shop' element={<Shop />} />
        <Route path='/shopdetails' element={<ShopDetails />} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/blogdetails' element={<BlogDetails />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/portfolio' element={<Portfolio />} />
        <Route path='/jobdetails' element={<JobDetails />} />
      </Routes>
    </>
  )
}

export default Index
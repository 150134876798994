import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Banner from '../MenShopDetails/Banner'
import Product from '../MenShopDetails/Product'
import Footer from '../Footer/Main'

function Main() {
    return (
        <>
            <Header />
            <main>
                <Breadcrumb mainheading={"Shop Details"} sub={"Home"} title={"Shop Details"} />
                <Banner />
                <Product />
            </main>
            <Footer />

        </>
    )
}

export default Main
import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Aboutarea from '../MenAboutUs/Aboutarea'
// import AbtBrand from '../MenAboutUs/AbtBrand'
// import Pricing from '../MenHomePage2/Pricing'
// import AbtVideo from '../MenAboutUs/AbtVideo'
import Teammember from '../MenHomePage2/Teammember'
// import Faq from '../MenHomePage2/Faq'
// import Testimonial from '../MenHomePage2/Testimonial'
import Footer from '../Footer/Main'
// import Gallery from '../MenPricing/Gallery'


import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTopOnPageChange() {
    const { pathname } = useLocation();
  
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
  }

function Main() {
    return (
        <>
            <ScrollToTopOnPageChange/>
            <Header />
            <main>
                <Breadcrumb mainheading={"About Us"} sub={"Home"} title={"About"} />
                <Aboutarea />
                {/* <Gallery/> */}
                {/* <AbtBrand /> */}
                {/* <Pricing /> */}
                {/* <AbtVideo /> */}
                {/* <Teammember /> */}
                {/* <Faq /> */}
                {/* <Testimonial /> */}
            </main>
            <Footer />

        </>
    )
}

export default Main
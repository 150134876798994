import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Gallery from '../MenProject/Gallery'
import Footer from '../Footer/Main'

function Main() {
    return (
        <>
            <Header />
            <main>
                <Breadcrumb mainheading={"Project"} sub={"Home"} title={"Project"} />
                <Gallery />
            </main>
            <Footer />
        </>
    )
}

export default Main
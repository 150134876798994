import React from 'react'
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
// import Logo from '../../assets/img/bg/sub-text-logo.png'
import Logo from '../../assets/img/logo/logo.png'
import Bg from '../../assets/img/bg/sr-h-bg.png'
import Icon1 from '../../assets/img/icon/sr-icon1.png'
import Icon1hover from '../../assets/img/icon/sr-icon2-hover.png'
import Icon2 from '../../assets/img/icon/sr-icon2.png'
import Icon2hover from '../../assets/img/icon/sr-icon1-hover.png'
import Icon3 from '../../assets/img/icon/sr-icon3.png'
import Icon3hover from '../../assets/img/icon/sr-icon3-hover.png'
import Icon4 from '../../assets/img/icon/sr-icon4.png'
import Icon4hover from '../../assets/img/icon/sr-icon4-hover.png'
import service_details from '../../services_details.json'
import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom';


// import sricon1 from '../../assets/img/icon/sr-icon1.png'
// import sricon1hover from '../../assets/img/icon/sr-icon1-hover.png'
// import sricon2 from '../../assets/img/icon/sr-icon2.png'
// import sricon2hover from '../../assets/img/icon/sr-icon2-hover.png'
// import sricon3 from '../../assets/img/icon/sr-icon3.png'
// import sricon3hover from '../../assets/img/icon/sr-icon3-hover.png'
// import sricon4 from '../../assets/img/icon/sr-icon4.png'
// import sricon4hover from '../../assets/img/icon/sr-icon4-hover.png' 
// import sricon9 from '../../assets/img/icon/sr-icon9.png'
// import sricon9hover from '../../assets/img/icon/sr-icon9-hover.png'
// import sricon10 from '../../assets/img/icon/sr-icon10.png'
// import sricon10hover from '../../assets/img/icon/sr-icon10-hover.png'
// import sricon11 from '../../assets/img/icon/sr-icon11.png'
// import sricon11hover from '../../assets/img/icon/sr-icon11-hover.png'
// import sricon12 from '../../assets/img/icon/sr-icon12.png'
// import sricon12hover from '../../assets/img/icon/sr-icon12-hover.png'






const Service = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}




function Services() {
   
    return (
        <>
            <section id="services" className="services-area services-bg  p-relative fix pt-120 pb-90" >
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-8 col-md-9">
                            <div className="section-title p-relative text-center mb-50 wow fadeInLeft  animated" data-animation="fadeInLeft" data-delay=".4s" >
                                <h5>
                                    <span className="line">
                                        {" "}
                                        <img src={Logo} alt="circle_right" width="75px" height="60px"/>
                                    </span>
                                </h5>
                                <h2>Our Best Services</h2>
                                <p>
                                Elevate your business with our comprehensive IT services, encompassing cutting-edge solutions in software development, cybersecurity, and cloud integration. At Vachilabs, we prioritize precision and innovation to seamlessly navigate the digital landscape, ensuring your success is not just a goal but a guarantee. Trust us to be your strategic IT partner, propelling your organization towards unparalleled efficiency and technological excellence.
                                </p>
                            </div>
                        </div>
                    </div>
                    <Slider className="row services-active" {...Service} slidesToShow={4} arrows={false}>
                    {
                        Object.keys(service_details).map((key, ind) => {
                            return(

                                <div className="col-lg-4 col-md-6">
                                    <div className="s-single-services mb-30 wow fadeInUp animated"
                                        data-animation="fadeInDown animated" data-delay=".2s" >
                                        <div className="services-icon">
                                            <img src={service_details[key].icon} alt="icon" />
                                            <img src={service_details[key].hovericon} alt="img" className="hover" />
                                        </div>
                                        <div className="second-services-content">
                                            <h5>
                                                <Link to={{ pathname: `/servicedetails/${key}`, state: key}}>{service_details[key].service_title}</Link>
                                            </h5>
                                            <p>
                                                {service_details[key].general_description}
                                            </p>
                                            <Link className="btn" to={{ pathname: `/servicedetails/${key}`, state: key}}>
                                                Read More <i className="fa-sharp fa-light fa-arrow-up" />
                                            </Link>
                                        </div>
                                        <div className="sr-h-bg">
                                            <img src={Bg} alt="icon" />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    </Slider>
                </div>
            </section>
        </>
    )
}

export default Services
import React from 'react'
import { Link } from 'react-router-dom'
import About04 from '../../assets/img/features/about_img_04.png'
import SubLogo from '../../assets/img/logo/logo.png'

function Area() {
    return (
        <>
            <section id="about" className="about-area about-p pt-120 pb-120 p-relative fix" >
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="s-about-img p-relative  wow fadeInLeft animated"
                                data-animation="fadeInLeft" data-delay=".4s" >
                                <img src={About04} alt="img" />
                                <div className="img-text">
                                    <h5>How much faster is Connect.</h5>
                                    <Link to="#">
                                        Learn More <i className="fa-sharp fa-light fa-arrow-up" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-30 wow fadeInRight  animated"
                                data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">
                                    <h5>
                                        <span className="line">
                                            {" "}
                                            <img src={SubLogo} width='75px' height='60px' alt="circle_right" />
                                        </span>{" "}
                                        What do We Do
                                    </h5>
                                    <h2>Grow your business with a creative team.</h2>
                                </div>
                                <p>
                                    More specific in terms of problem solving approach with end to end
                                    architecture, design, develop and deploy, including monitoring system,
                                    notification system and authentication system as a plug and play solution.
                                    We are experienced in all of the modern technologies including generative AI,
                                    cloud and IoT. Join with us for more interesting and efficient solutions
                                    for your business problems and grow.
                                </p>
                                <div className="skills-content s-about-content">
                                    <div className="skills-content s-about-content">
                                        <div className="skills row">
                                            <div className="col-lg-6 skill">
                                                <div className="skill-name">
                                                    <strong>Technical Strength 100%</strong>
                                                </div>
                                                <div className="skill-bar mb-20">
                                                    <div className="skill-per" style={{ width: '100%' }} id={94} />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 skill">
                                                <div className="skill-name">
                                                    <strong>Business Strategy 100%</strong>
                                                </div>
                                                <div className="skill-bar mb-20">
                                                    <div className="skill-per" style={{ width: '100%' }} id={84} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center mt-20">
                                    <div className="col-lg-12">
                                        <div className="slider-btn">
                                            <Link to="/aboutus" className="btn ss-btn2 mr-15"
                                                data-animation="fadeInRight" data-delay=".8s" >
                                                Discover More <i className="fa-sharp fa-light fa-arrow-up" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Area
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Shop1 from '../../assets/img/shop/details/thumb1.jpg'
import Shop2 from '../../assets/img/shop/details/thumb2.jpg'
import Shop3 from '../../assets/img/shop/details/thumb3.jpg'
import ShopLarge1 from '../../assets/img/shop/details/large1.jpg'
import ShopLarge2 from '../../assets/img/shop/details/large2.jpg'
import ShopLarge3 from '../../assets/img/shop/details/large3.jpg'

const tabData = [
    {
        id: 'a',
        thumb: Shop1,
        large: ShopLarge1,
    },
    {
        id: 'b',
        thumb: Shop2,
        large: ShopLarge2,
    },
    {
        id: 'c',
        thumb: Shop3,
        large: ShopLarge3,
    },
];

function Banner() {
    const [tabMenu, setTabActive] = useState({ a: true });

    return (
        <>
            <section className="shop-banner-area pt-120 pb-90" data-animation="fadeInUp animated" data-delay=".2s">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7">
                            <div className="shop-thumb-tab mb-30">
                                <ul className="nav" id="myTab2" role="tablist">
                                    {tabData.map((tab) => (
                                        <li className="nav-item" key={tab.id}>
                                            <Link className={`nav-link ${tabMenu[tab.id] ? 'active' : ''}`}
                                                onClick={() => setTabActive({ [tab.id]: true })}
                                                id={`${tab.id}-tab`} data-bs-toggle="tab" to={`#${tab.id}`} role="tab" aria-selected={tabMenu[tab.id]} >
                                                <img src={tab.thumb} alt="" />
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="product-details-img mb-30">
                                <div className="tab-content" id="myTabContent2">
                                    {tabData.map((tab) => (
                                        <div className={`tab-pane fade ${tabMenu[tab.id] ? 'show active' : ''}`} id={tab.id} role="tabpanel" key={tab.id}>
                                            <div className="product-large-img">
                                                <img src={tab.large} alt="" />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5">
                            <div className="product-details mb-30">
                                <div className="product-details-title">
                                    <p>Workstead</p>
                                    <h1>Helios Piranho Lamp</h1>
                                    <div className="price details-price pb-30 mb-20">
                                        <span>$700.00</span>
                                        <span className="old-price">$820.00</span>
                                    </div>
                                </div>
                                <p>
                                    It is a long established fact that a reader will be distracted by
                                    the readable content of a page when looking at its layout. The point
                                    of using Lorem Ipsum is that it has a more-or-less normal
                                    distribution of letters, as opposed to using 'Content here, content
                                    here,' making it look like readable English.
                                </p>
                                <div className="product-cat mt-30 mb-30">
                                    <span>Category: </span>
                                    <Link to="#">furniture,</Link>
                                    <Link to="#">decor</Link>
                                </div>
                                <div className="product-details-action">
                                    <form action="#">
                                        <div className="plus-minus">
                                            <div className="cart-plus-minus">
                                                <input type="text" defaultValue={1} />
                                            </div>
                                        </div>
                                        <button className="btn btn-black" type="submit">
                                            add to cart
                                        </button>
                                    </form>
                                </div>
                                <div className="product-social mt-45">
                                    <Link to="#">
                                        <i className="fab fa-facebook-f" />
                                    </Link>
                                    <Link to="#">
                                        <i className="fab fa-twitter" />
                                    </Link>
                                    <Link to="#">
                                        <i className="fab fa-behance" />
                                    </Link>
                                    <Link to="#">
                                        <i className="fab fa-linkedin-in" />
                                    </Link>
                                    <Link to="#">
                                        <i className="fab fa-youtube" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Banner;

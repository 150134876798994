import React from 'react'
import Portfolioimg from '../../assets/img/bg/protfolio-details.png'

function PortfolioImg() {
    return (
        <>
            <div className="servcies-img">
                <figure className="image">
                    <img src={Portfolioimg} alt="service" />
                </figure>
            </div>

        </>
    )
}

export default PortfolioImg
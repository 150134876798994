import React from 'react'
import { Link } from 'react-router-dom'
import BGIMG from '../../assets/img/slider/slider2.png'

function Slider() {
    return (
        <>
            <section id="parallax" className="slider-area slider-two fix p-relative">
                <div className="slider-active2">
                    <div className="single-slider slider-bg2 d-flex align-items-center"
                        style={{ backgroundImage: `url(${BGIMG})`, backgroundPosition: "center center" }} >
                        <div className="container">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-lg-6 col-md-6">  
                                    <div className="slider-content s-slider-content2">
                                        <h2 data-animation="fadeInUp" data-delay=".4s">
                                            Elevate Your Business with Cutting-Edge AI platform
                                        </h2>
                                        <p data-animation="fadeInUp" data-delay=".6s">
                                        Welcome to Vachilabs Pvt Ltd, your premier destination for innovative and effective digital solutions. As a leading smart digital creative web agency, we specialize in delivering a comprehensive suite of services that seamlessly blend creativity, technology, and strategy to elevate your online presence.
                                        </p>
                                        <div className="slider-btn mt-30">
                                            <Link to="/aboutus" className="btn mr-15"
                                                data-animation="fadeInUp" data-delay=".4s" >
                                                Explore More <i className="fa-sharp fa-light fa-arrow-up" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Slider
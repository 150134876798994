import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import ServiceImg from '../MenServiceDetails/ServiceImg'
import Abtarea from '../MenServiceDetails/Abtarea'
import Footer from '../Footer/Main'
// import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom';

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTopOnPageChange() {
  const { pathname } = useLocation();

  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function Main() {


    return (
        <>
        <ScrollToTopOnPageChange/>
            <Header />
            <main>
                <Breadcrumb mainheading={"Services Details"} sub={"Home"} title={"Services Details"} />
                <ServiceImg />
                <Abtarea/>
            </main>
            <Footer />

        </>
    )
}

export default Main
import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import PricingPlan from '../MenPricing/PricingPlan'
import Counter from '../MenPricing/Counter'
// import Gallery from '../MenPricing/Gallery'
// import Faq from '../MenHomePage2/Faq'
// import Teammember from '../MenHomePage2/Teammember'
import Cta from '../MenHomePage1/Cta'
import Footer from '../Footer/Main'

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTopOnPageChange() {
  const { pathname } = useLocation();

  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}




function Main() {
    return (
        <>
            <ScrollToTopOnPageChange/>
            <Header />
            <main>
                <Breadcrumb mainheading={"Pricing"} sub={"Home"} title={"Pricing"} />
                <PricingPlan />
                <Counter />
                {/* <Gallery /> */}
                {/* <Faq />
                <Teammember /> */}
                <Cta />
            </main>
            <Footer />
        </>
    )
}

export default Main
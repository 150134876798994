import React from 'react'
import HeaderTwo from '../HeaderTwo/Main'
import Slider from '../MenHomePage2/Slider'
import About from '../MenHomePage2/About'
// import Gallery from '../MenHomePage2/Gallery'
import Area from '../MenHomePage1/Area'
// import Pricing from '../MenHomePage2/Pricing'
// import Counter from '../MenHomePage2/Counter'
// import Faq from '../MenHomePage2/Faq'
// import Teammember from '../MenHomePage2/Teammember'
// import Testimonial from '../MenHomePage2/Testimonial'
// import Blog from '../MenHomePage2/Blog'
import Cta from '../MenHomePage1/Cta'
import Footer from '../Footer/Main'
import Services from '../MenHomePage1/Services'


import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTopOnPageChange() {
    const { pathname } = useLocation();
  
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
  }

function Main() {
    return (
        <>
            <ScrollToTopOnPageChange/>
            <HeaderTwo />
            <main>
                <Slider />
                <Services />
                <About />
                {/* <Gallery /> */}
                <Area />
                {/* <Pricing /> */}
                {/* <Counter /> */}
                {/* <Faq /> */}
                {/* <Teammember /> */}
                {/* <Testimonial /> */}
                {/* <Blog /> */}
                <Cta />
            </main>
            <Footer />
        </>
    )
}

export default Main
import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Area from '../MenShop/Area'
import Footer from '../Footer/Main'

function Main() {
    return (
        <>
            <Header />
            <main>
                <Breadcrumb mainheading={"Shop"} sub={"Home"} title={"Shop"} />
                <Area />
            </main>
            <Footer />
        </>
    )
}

export default Main
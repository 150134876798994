import React from 'react'
import ServiceImg1 from '../../assets/img/bg/service-details-new.png'

function ServiceImg() {
    return (
        <>
            <img src={ServiceImg1} alt="service"/>
        </>
    )
}

export default ServiceImg
import React from 'react'
// import { Link } from 'react-router-dom'
import AboutImg2 from '../../assets/img/features/about_img_02.png'
import SubLogo from '../../assets/img/logo/logo.png'
// import Fplay from '../../assets/img/features/fplay.png'

function Aboutarea() {
    return (
        <>
            <section id="about" className="about-area about-p pt-120 pb-120 p-relative fix" >
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s" >
                                <img src={AboutImg2} alt="img" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="about-content s-about-content wow fadeInRight  animated" data-animation="fadeInRight"
                                data-delay=".4s" >
                                <div className="about-title second-title pb-25">
                                    <h5>
                                        <span className="line">
                                            {" "}
                                            <img src={SubLogo} width='60px' height='50px' alt="circle_right" />
                                        </span>{" "}
                                        About Us Vachilabs
                                    </h5>
                                    <h2>We Provide Best Experience to You</h2>
                                </div>
                                <p className="pline">
                                Welcome to Vachi Labs Innovations Private Limited, a leading IT service company at the forefront of technological innovation, strategically located in the heart of Central India, Bhopal. Established with a vision to empower businesses with cutting-edge AI solutions, Vachi Labs is dedicated to delivering excellence in the digital landscape.
                                </p>
                                <div className="about-content2">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <ul className="green2">
                                                <li>
                                                    <div className="abcontent">
                                                        <div className="ano">
                                                            <span>01</span>
                                                        </div>
                                                        <div className="text">
                                                            <h3>
                                                                Our Mission
                                                            </h3>{" "}
                                                        </div>
                                                    </div>
                                                    <p >
                                                    Our mission is to revolutionize the way businesses operate by harnessing the power of Artificial Intelligence. We are committed to providing innovative and tailored IT solutions that enhance efficiency, boost productivity, and drive sustainable growth for our clients.
                                                    </p>
                                                </li>
                                                <li>
                                                    <div className="abcontent">
                                                        <div className="ano">
                                                            <span>02</span>
                                                        </div>
                                                        <div className="text">
                                                            <h3>
                                                                AI Expertise
                                                            </h3>
                                                        </div>
                                                    </div>
                                                    <p>
                                                    We specializes in leveraging Artificial Intelligence to solve complex business challenges.
                                                    Our team of skilled professionals is adept at developing and implementing AI-driven applications,
                                                    machine learning solutions, and automation tools that elevate the performance of our clients' operations.
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="about-content2 row justify-content-center align-items-center">
                        <div className="col-md-12">
                            <ul className="green2">
                                <li>
                                    <div className="abcontent" >
                                        <div className="ano">
                                            <span>03</span>
                                        </div>
                                        <div className="text">
                                            <h3>
                                            Comprehensive IT Services
                                            </h3>{" "}
                                        </div>
                                    </div>
                                    <p Style={"padding: 5px"}>
                                    From custom software development and web application design to cloud computing solutions and cybersecurity, Vachi Labs provides end-to-end IT services that align with the latest industry trends.
                                    </p>
                                </li>
                                <li >
                                    <div className="abcontent">
                                        <div className="ano">
                                            <span>04</span>
                                        </div>
                                        <div className="text">
                                            <h3>
                                            Client-Centric Approach
                                            </h3>
                                        </div>
                                    </div>
                                    <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    We prioritize the success of our clients. Our client-centric approach involves understanding unique business requirements and tailoring solutions that address specific challenges. We foster strong and collaborative partnerships to ensure long-term success for both parties.
    
                                    </p>
                                </li>
                                <li>
                                    <div className="abcontent">
                                        <div className="ano">
                                            <span>05</span>
                                        </div>
                                        <div className="text">
                                            <h3>
                                            Commitment to Quality
                                            </h3>
                                        </div>
                                    </div>
                                    <p Style={"padding: 10px"}>
                                    Quality is the cornerstone of our operations. Vachi Labs is dedicated to delivering high-quality solutions that adhere to industry standards and best practices. Our rigorous quality assurance processes ensure that every project we undertake meets and exceeds the expectations of our clients.
                                    </p>
                                </li>
                                <li>
                                    <div className="abcontent">
                                        <div className="ano">
                                            <span>06</span>
                                        </div>
                                        <div className="text">
                                            <h3>
                                            Bhopal, Central India
                                            </h3>
                                        </div>
                                    </div>
                                    <p>
                                    Situated in Bhopal, Central India, Vachi Labs benefits from a strategic location that facilitates seamless collaboration with clients across the nation. Our central location allows us to stay well-connected and agile in delivering timely and effective solutions.
                                    </p>
                                </li>
                                <li>
                                <div className="abcontent">
                                    <div className="ano">
                                        <span>07</span>
                                    </div>
                                    <div className="text">
                                        <h3>
                                        Join us in Shaping the Future
                                        </h3>
                                    </div>
                                </div>
                                <p>
                                At Vachi Labs, we invite you to join us in shaping the future of technology. Whether you are a business looking for innovative IT solutions or a professional seeking to be part of a dynamic and forward-thinking team, Vachi Labs is your partner in progress.
Contact us today to explore how Vachi Labs can transform your business through the power of AI and advanced IT services.

                                </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Aboutarea
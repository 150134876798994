import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Teamarea from '../MenTeamDetails/Teamarea'
import Footer from '../Footer/Main'

function Main() {
    return (
        <>
            <Header />
            <main>
                <Breadcrumb mainheading={"Team Details"} sub={"Home"} title={"Team Details"} />
                <Teamarea />
            </main>
            <Footer />

        </>
    )
}

export default Main
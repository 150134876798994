import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Map from '../MenContact/Map'
import Area from '../MenContact/Area'
// import Brands from '../MenContact/Brands'
import Footer from '../Footer/Main'

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTopOnPageChange() {
  const { pathname } = useLocation();

  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function Main() {
    return (
        <>
        <ScrollToTopOnPageChange/>
            <Header />
            <main>
                <Breadcrumb mainheading={"Contact Us"} sub={"Home"} title={"Contact Us"} />
                <Map />
                <Area />
                {/* <Brands /> */}
            </main>
            <Footer />
        </>
    )
}

export default Main
import React, { useState, useEffect } from 'react';

function Counter() {
    const initialCounts = {
        Clients: 0,
        Projects: 0,
        Team: 0,
        Partners: 0,
    };
    const finalCounts = {
        Clients: 100,
        Projects: 18,
        Team: 15,
        Partners: 50,
    };

    const counters = [
        {
            label: 'Satisfaction Clients',
            unit: '%',
            key: 'Clients',
        },
        {
            label: 'Projects Completed',
            unit: '+',
            key: 'Projects',
        },
        {
            label: 'Team Members',
            unit: '+',
            key: 'Team',
        },
        {
            label: 'Clients and partners worldwide',
            unit: '+',
            key: 'Partners',
        },
    ];

    const [counts, setCounts] = useState(initialCounts);

    useEffect(() => {
        const updateCounter = () => {
            const interval = 1;
            const timers = counters.map((counter) => {
                const { key } = counter;
                const startCount = counts[key];
                const endCount = finalCounts[key];
                const step = Math.ceil((endCount - startCount) / (1000 / interval));

                return setInterval(() => {
                    setCounts((prevCounts) => ({
                        ...prevCounts,
                        [key]: Math.min(prevCounts[key] + step, endCount),
                    }));
                }, interval);
            });
            return () => {
                timers.forEach((timer) => clearInterval(timer));
            };
        };
        updateCounter();
    }, []);

    return (
        <>
            <div className="counter-area p-relative pb-120">
                <div className="container">
                    <div className="row p-relative">
                        {counters.map((counter, index) => (
                            <div key={index} className="col-lg-3 col-md-6 col-sm-12">
                                <div className="single-counter wow fadeInUp animated" data-animation="fadeInDown animated" data-delay=".2s">
                                    <div className="counter p-relative">
                                        <span className="count">{counts[counter.key]}</span>
                                        <span>{counter.unit}</span>
                                        <p>{counter.label}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Counter;

import React from 'react'
import Servicedtls02 from '../../assets/img/bg/service-details2.png'
import service_details from '../../services_details.json'
import icon33 from '../../assets/img/bg/new/service_product_engineering.png'
import mobile_app from '../../assets/img/bg/new/service_mobile_app.png'
import erps from "../../assets/img/bg/new/service_erps.png"
import website_webapp from "../../assets/img/bg/new/service_website_webapp.png"
import server_hosting from "../../assets/img/bg/new/service_server_hosting.png"
import email_account_hosting from "../../assets/img/bg/new/service_email_account_hosting.png"
import digital_marketing from "../../assets/img/bg/new/service_digital_marketing.png"
import product_engineering from "../../assets/img/bg/new/service_product_engineering.png"
import business_model from "../../assets/img/bg/new/service_business_model.png"
import staffing_service from  "../../assets/img/bg/new/staffing_service.png"



function Abtarea() {
    const currentUrl = window.location.href;
    const urlParts = currentUrl.split('/');
    const service_id = urlParts[urlParts.length - 1];
    const imageMap = {
        mobile_app : mobile_app,
        erps: erps,
        website_webapp: website_webapp,
        server_hosting: server_hosting,
        email_account_hosting: email_account_hosting,
        digital_marketing : digital_marketing,
        product_engineering : product_engineering,
        business_model : business_model,
        staffing_service: staffing_service
    }

    return (
        <>
            <div className="about-area5 about-p p-relative inner-blog">
                <div className="container pt-50 pb-90">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-9 col-md-12 col-sm-12 order-2">
                            <div className="service-detail">
                                <div className="content-box">
                                    <h2>{service_details[service_id].service_title}</h2>
                                    <p>{service_details[service_id]["details_description"]}</p>
                                    {
                                        
                                        service_details[service_id]["offering_details"].map((key, index) => {
                                            return(
                                            <>
                                                
                                                <h6>{key.heading}</h6>
                                                <p>{key.text}</p>
                                            </>
                                            )
                                        })
                                    }
                                    <div className="two-column mt-50">
                                        <div className="row">
                                            <div className="image-column col-xl-6 col-lg-12 col-md-12">
                                                <figure className="image">
                                                    {
                                                    <img key={service_id} src={imageMap[service_id]} alt="service"/>
                                                    }
                                                </figure>
                                            </div>
                                            <div className="text-column col-xl-6 col-lg-12 col-md-12">
                                                <h3>Highlights</h3>
                                                <ul>
                                                {
                                        
                                                    service_details[service_id]["bullate_points"].map((key, index) => {
                                                        return(
                                                        <>
                                                            <li key={index}>{key}</li>
                                                        </>
                                                        )
                                                    })
                                                }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <p>
                                        {service_details[service_id]["service_footer"]}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Abtarea
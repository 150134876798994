import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Innerblog from '../Menblog/Innerblog'
import Footer from '../Footer/Main'

function Main() {
    return (
        <>
            <Header />
            <main>
                <Breadcrumb mainheading={"Blog"} sub={"Home"} title={"Blog"} />
                <Innerblog />
            </main>
            <Footer />
        </>
    )
}

export default Main
const JOB_DETAILS = {
    "flutter_developer": {
        "job_id" : "43284738",
        "job_title": "Flutter developer",
        "location" : "Bhopal",
        "salary" : "as per industry standard",
        "summary" : "We are a cutting-edge tech company dedicated to revolutionizing the mobile experience. Our team is driven by creativity, collaboration, and a commitment to excellence. With exciting projects on the horizon, we're searching for a skilled Flutter Developer to help bring our visions to life.",
        "Responsibilities" : [
                "Develop and maintain high-quality mobile applications using Flutter framework",
                "Collaborate with cross-functional teams to define, design, and ship new features",
                "Ensure the performance, quality, and responsiveness of applications",
                "Identify and resolve bottlenecks, bugs, and other technical issues",
                "Stay updated on emerging technologies and industry trends to continuously improve development processes."


        ],
        "quote_by" : "Bill Gates",
        "quote" : "”Learning to write programs stretches your mind, and helps you think better, creates a way of thinking about things that I think is helpful in all domains.”",
        "Qualifications" : [
            "Bachelor's degree in Computer Science or related field (preferred)",
            "Proven experience as a Flutter Developer or similar role",
            "Strong knowledge of Dart programming language and Flutter framework",
            "Familiarity with RESTful APIs and mobile UI/UX principles",
            "Solid understanding of software development lifecycle and agile methodologies",
            "Excellent problem-solving and communication skills"
        ]
    },
    "frontend_developer": {
        "job_id" : "72784790",
        "job_title": "Frontend developer",
        "location" : "Bhopal",
        "salary" : "As per industry standard",
        "summary" : "We're a forward-thinking tech company dedicated to pushing the boundaries of innovation. Our team thrives on collaboration, creativity, and a shared commitment to excellence. With exciting projects in the pipeline, we're seeking passionate individuals who are eager to make an impact in the world of web development.",
        "Responsibilities" : [
                "Develop and maintain high-quality mobile applications using Flutter framework",
                "Collaborate with cross-functional teams to define, design, and ship new features",
                "Optimize applications for maximum speed and scalability",
                "Ensure the performance, quality, and responsiveness of applications",
                "Identify and resolve bottlenecks, bugs, and other technical issues",
                "Stay updated on emerging technologies and industry trends to continuously improve development processes."


        ],
        "quote_by" : "John Johnson",
        "quote" : "First, solve the problem.Then write the code.",
        "Qualifications" : [
            "Bachelor’s degree in computer science or related field (preferred)",
            "Proven experience as a Front-End Developer or similar role (3-6Years)",
            "Proficiency in HTML5, CSS3, JavaScript, and modern front-end frameworks (e.g., React, Angular, Vue.js)",
            "Strong understanding of responsive design principles and mobile-first development",
            "Experience with version control systems (e.g., Git)",
            "Solid knowledge of web performance optimization techniques",
            "Excellent problem-solving and communication skills.",
            "Good understanding on State management, Redux",
            "Ability to integrate rest API, AWS connections, Redis connections, WebSocket connections",
            "Should be familiar with Docker, YAML, git, bitbucket, JIRA.",
            " Understanding on deployment pipeline for AWS is the added advantage.",
            "Should be familiar with test driven development (TDD) and knowledge on any documentation tool like sphinx, swagger.",
            "Should have good understanding on Algorithm and data structure with good programming skills."
        ]
    },
    "tester": {
        "job_id" : "82384765",
        "job_title": "Tester",
        "location" : "Bhopal",
        "salary" : "As per industry standard",
        "summary" : "We are a leading tech company dedicated to delivering cutting-edge solutions to our clients. Our team is driven by a shared commitment to excellence, collaboration, and pushing the boundaries of technology. With exciting projects on the horizon, we're looking for a skilled Software Tester to help us maintain the highest standards of quality in our products.",
        "Responsibilities" : [
            "Develop and execute test plans, test cases, and test scripts to ensure the quality of software products.",
            "Collaborate with cross-functional teams to understand project requirements and identify test scenarios.",
            "Perform manual and automated testing to detect and report software defects.",
            "Analyze test results and provide feedback to developers to facilitate bug fixes.",
            "Participate in project meetings and provide input on quality-related issues.",
            "Stay updated on industry best practices and emerging trends in software testing.",
            "Reviewing and validating user stories and requirements.",
            "Executing all levels of testing, such as system, integration and regression.",
            "Understanding in performance testing, load testing, security testing.",
            "Sonar Qube integration and JMeter.",
            "Understanding VAPT (Vulnerability Testing and Penetration Testing).",
            "Developing automation scripts when required.",
            "Identifying software defects and providing solutions.",
            "Providing software improvement recommendations.",
            "Creating relevant documentation, such as test plans.",
            "Communicating project updates with IT Fvteams and stakeholders."
        ],
        "quote_by" : "John Johnson",
        "quote" : "First, solve the problem.Then write the code.",
        "Qualifications" : [
            "Bachelor's degree in Computer Science or related field (preferred).",
            "Proven experience (3-5Years) as a Software Tester or similar role.",
            "Strong understanding of software testing methodologies and processes.",
            "Proficiency in manual testing techniques and tools.",
            "Experience with test automation tools/frameworks (e.g., Selenium, Appium).",
            "Solid knowledge of programming languages (e.g., Java, Python) for test automation.",
            "Excellent analytical and problem-solving skills."
        ]
    }
}

export default JOB_DETAILS
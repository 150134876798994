import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import Gallery1 from '../../assets/img/gallery/protfolio-img01.png'
import Gallery2 from '../../assets/img/gallery/protfolio-img02.png'
import Gallery3 from '../../assets/img/gallery/protfolio-img03.png'
import Gallery4 from '../../assets/img/gallery/protfolio-img04.png'
import Gallery5 from '../../assets/img/gallery/protfolio-img15.png'
import Gallery6 from '../../assets/img/gallery/protfolio-img16.png'

function Gallery() {
    const [tabMenu, tabActive] = useState({ a: true });

    return (
        <>
            <section id="work" className="pt-120 pb-105 work-main-block">
                <div className="container">
                    <div className="portfolio ">
                        <div className="row align-items-end">
                            <div className="col-lg-12">
                                <div className="my-masonry text-center mb-50 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                    <div className="button-group filter-button-group ">
                                        <button className={`*  ${tabMenu.a && "active"}`} onClick={() => tabActive({ a: true })} id="all-tab" data-bs-toggle="tab" to="#id-all" role="tab" aria-controls="all" aria-selected="true" >
                                            View All
                                        </button>
                                        <button className={`.financial  ${tabMenu.b && "show active"}`} onClick={() => tabActive({ b: true })} id="id-web-des" data-bs-toggle="tab" to="#id-web-des" role="tab" aria-controls="webdesign" aria-selected="false" >
                                            Web Design
                                        </button>
                                        <button className={`.banking  ${tabMenu.c && "show active"}`} onClick={() => tabActive({ c: true })} id="id-banking" data-bs-toggle="tab" to="#id-banking" role="tab" aria-controls="UI/UXdesigen" aria-selected="false" >
                                            UX/UI Design
                                        </button>
                                        <button className={`.insurance  ${tabMenu.d && "show active"}`} onClick={() => tabActive({ d: true })} id="id-ins" data-bs-toggle="tab" to="#id-ins" role="tab" aria-controls="Branding" aria-selected="false">
                                            Branding
                                        </button>
                                        <button className={`.family  ${tabMenu.e && "show active"}`} onClick={() => tabActive({ e: true })} id="id-app" data-bs-toggle="tab" to="#id-app" role="tab" aria-controls="appdesigen" aria-selected="false">
                                            Apps Design
                                        </button>
                                        <button className={`.marketing  ${tabMenu.f && "show active"}`} onClick={() => tabActive({ f: true })} id="id-marketing" data-bs-toggle="tab" to="#id-marketing" role="tab" aria-controls="marketing" aria-selected="false">
                                            Marketing
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-content grid col2 wow fadeInUp  animated" id="myTabContent" data-animation="fadeInUp" data-delay=".4s" >
                            <div className={`tab-pane fade   ${tabMenu.a && "show active"}`} id="id-all" role="tabpanel" aria-labelledby="all-tab" >
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <div className="grid-item financial">
                                            <Link to="/Portfolio"></Link>
                                            <figure className="gallery-image">
                                                <Link to="/Portfolio">
                                                    <img src={Gallery1} alt="img" className="img" />
                                                </Link>
                                                <figcaption>
                                                    <Link to="/Portfolio"></Link>
                                                    <Link to="/Portfolio">
                                                        <p>Branding Design</p>
                                                        <h3>Fashion Chair Design Brand</h3>
                                                    </Link>
                                                    <div className="slider-btn">
                                                        <Link to="/Portfolio" tabIndex={0}>
                                                            <i className="fa-sharp fa-light fa-arrow-up" />
                                                        </Link>
                                                    </div>
                                                </figcaption>
                                            </figure>
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className="grid-item financial banking">
                                            <Link to="/Portfolio"></Link>
                                            <figure className="gallery-image">
                                                <Link to="/Portfolio">
                                                    <img src={Gallery2} alt="img" className="img" />
                                                </Link>
                                                <figcaption>
                                                    <Link to="/Portfolio"></Link>
                                                    <Link to="/Portfolio">
                                                        <p>Branding Design</p>
                                                        <h3>Fashion Chair Design Brand</h3>
                                                    </Link>
                                                    <div className="slider-btn">
                                                        <Link to="/Portfolio" tabIndex={0}>
                                                            <i className="fa-sharp fa-light fa-arrow-up" />
                                                        </Link>
                                                    </div>
                                                </figcaption>
                                            </figure>
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className="grid-item insurance">
                                            <Link to="/Portfolio"></Link>
                                            <figure className="gallery-image">
                                                <Link to="/Portfolio">
                                                    <img src={Gallery3} alt="img" className="img" />
                                                </Link>
                                                <figcaption>
                                                    <Link to="/Portfolio"></Link>
                                                    <Link to="/Portfolio">
                                                        <p>Branding Design</p>
                                                        <h3>Fashion Chair Design Brand</h3>
                                                    </Link>
                                                    <div className="slider-btn">
                                                        <Link to="/Portfolio" tabIndex={0}>
                                                            <i className="fa-sharp fa-light fa-arrow-up" />
                                                        </Link>
                                                    </div>
                                                </figcaption>
                                            </figure>
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className="grid-item family">
                                            <Link to="/Portfolio"></Link>
                                            <figure className="gallery-image">
                                                <Link to="/Portfolio">
                                                    <img src={Gallery4} alt="img" className="img" />
                                                </Link>
                                                <figcaption>
                                                    <Link to="/Portfolio"></Link>
                                                    <Link to="/Portfolio">
                                                        <p>Branding Design</p>
                                                        <h3>Fashion Chair Design Brand</h3>
                                                    </Link>
                                                    <div className="slider-btn">
                                                        <Link to="/Portfolio" tabIndex={0}>
                                                            <i className="fa-sharp fa-light fa-arrow-up" />
                                                        </Link>
                                                    </div>
                                                </figcaption>
                                            </figure>
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className="grid-item financial banking">
                                            <Link to="/Portfolio"></Link>
                                            <figure className="gallery-image">
                                                <Link to="/Portfolio">
                                                    <img src={Gallery5} alt="img" className="img" />
                                                </Link>
                                                <figcaption>
                                                    <Link to="/Portfolio"></Link>
                                                    <Link to="/Portfolio">
                                                        <p>Branding Design</p>
                                                        <h3>Fashion Chair Design Brand</h3>
                                                    </Link>
                                                    <div className="slider-btn">
                                                        <Link to="/Portfolio" tabIndex={0}>
                                                            <i className="fa-sharp fa-light fa-arrow-up" />
                                                        </Link>
                                                    </div>
                                                </figcaption>
                                            </figure>
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className="grid-item insurance">
                                            <Link to="/Portfolio"></Link>
                                            <figure className="gallery-image">
                                                <Link to="/Portfolio">
                                                    <img src={Gallery6} alt="img" className="img" />
                                                </Link>
                                                <figcaption>
                                                    <Link to="/Portfolio"></Link>
                                                    <Link to="/Portfolio">
                                                        <p>Branding Design</p>
                                                        <h3>Fashion Chair Design Brand</h3>
                                                    </Link>
                                                    <div className="slider-btn">
                                                        <Link to="/Portfolio" tabIndex={0}>
                                                            <i className="fa-sharp fa-light fa-arrow-up" />
                                                        </Link>
                                                    </div>
                                                </figcaption>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`tab-pane fade   ${tabMenu.b && "show active"}`} id="id-web-des" role="tabpanel" aria-labelledby="id-web-des" >
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <div className="grid-item financial">
                                            <Link to="/Portfolio"></Link>
                                            <figure className="gallery-image">
                                                <Link to="/Portfolio">
                                                    <img src={Gallery1} alt="img" className="img" />
                                                </Link>
                                                <figcaption>
                                                    <Link to="/Portfolio"></Link>
                                                    <Link to="/Portfolio">
                                                        <p>Branding Design</p>
                                                        <h3>Fashion Chair Design Brand</h3>
                                                    </Link>
                                                    <div className="slider-btn">
                                                        <Link to="/Portfolio" tabIndex={0}>
                                                            <i className="fa-sharp fa-light fa-arrow-up" />
                                                        </Link>
                                                    </div>
                                                </figcaption>
                                            </figure>
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className="grid-item financial banking">
                                            <Link to="/Portfolio"></Link>
                                            <figure className="gallery-image">
                                                <Link to="/Portfolio">
                                                    <img src={Gallery2} alt="img" className="img" />
                                                </Link>
                                                <figcaption>
                                                    <Link to="/Portfolio"></Link>
                                                    <Link to="/Portfolio">
                                                        <p>Branding Design</p>
                                                        <h3>Fashion Chair Design Brand</h3>
                                                    </Link>
                                                    <div className="slider-btn">
                                                        <Link to="/Portfolio" tabIndex={0}>
                                                            <i className="fa-sharp fa-light fa-arrow-up" />
                                                        </Link>
                                                    </div>
                                                </figcaption>
                                            </figure>
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className="grid-item financial banking">
                                            <Link to="/Portfolio"></Link>
                                            <figure className="gallery-image">
                                                <Link to="/Portfolio">
                                                    <img src={Gallery5} alt="img" className="img" />
                                                </Link>
                                                <figcaption>
                                                    <Link to="/Portfolio"></Link>
                                                    <Link to="/Portfolio">
                                                        <p>Branding Design</p>
                                                        <h3>Fashion Chair Design Brand</h3>
                                                    </Link>
                                                    <div className="slider-btn">
                                                        <Link to="#" tabIndex={0}>
                                                            <i className="fa-sharp fa-light fa-arrow-up" />
                                                        </Link>
                                                    </div>
                                                </figcaption>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`tab-pane fade   ${tabMenu.c && "show active"}`} id="id-banking" role="tabpanel" aria-labelledby="id-banking" >
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <div className="grid-item financial banking">
                                            <Link to="/Portfolio"></Link>
                                            <figure className="gallery-image">
                                                <Link to="/Portfolio">
                                                    <img src={Gallery2} alt="img" className="img" />
                                                </Link>
                                                <figcaption>
                                                    <Link to="/Portfolio"></Link>
                                                    <Link to="/Portfolio">
                                                        <p>Branding Design</p>
                                                        <h3>Fashion Chair Design Brand</h3>
                                                    </Link>
                                                    <div className="slider-btn">
                                                        <Link to="/Portfolio" tabIndex={0}>
                                                            <i className="fa-sharp fa-light fa-arrow-up" />
                                                        </Link>
                                                    </div>
                                                </figcaption>
                                            </figure>
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className="grid-item financial banking">
                                            <Link to="/Portfolio"></Link>
                                            <figure className="gallery-image">
                                                <Link to="/Portfolio">
                                                    <img src={Gallery5} alt="img" className="img" />
                                                </Link>
                                                <figcaption>
                                                    <Link to="/Portfolio"></Link>
                                                    <Link to="/Portfolio">
                                                        <p>Branding Design</p>
                                                        <h3>Fashion Chair Design Brand</h3>
                                                    </Link>
                                                    <div className="slider-btn">
                                                        <Link to="/Portfolio" tabIndex={0}>
                                                            <i className="fa-sharp fa-light fa-arrow-up" />
                                                        </Link>
                                                    </div>
                                                </figcaption>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`tab-pane fade   ${tabMenu.d && "show active"}`} id="id-ins" role="tabpanel" aria-labelledby="id-ins" >
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <div className="grid-item insurance">
                                            <Link to="/Portfolio"></Link>
                                            <figure className="gallery-image">
                                                <Link to="/Portfolio">
                                                    <img src={Gallery3} alt="img" className="img" />
                                                </Link>
                                                <figcaption>
                                                    <Link to="/Portfolio"></Link>
                                                    <Link to="/Portfolio">
                                                        <p>Branding Design</p>
                                                        <h3>Fashion Chair Design Brand</h3>
                                                    </Link>
                                                    <div className="slider-btn">
                                                        <Link to="/Portfolio" tabIndex={0}>
                                                            <i className="fa-sharp fa-light fa-arrow-up" />
                                                        </Link>
                                                    </div>
                                                </figcaption>
                                            </figure>
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className="grid-item insurance">
                                            <Link to="/Portfolio"></Link>
                                            <figure className="gallery-image">
                                                <Link to="/Portfolio">
                                                    <img src={Gallery6} alt="img" className="img" />
                                                </Link>
                                                <figcaption>
                                                    <Link to="/Portfolio"></Link>
                                                    <Link to="/Portfolio">
                                                        <p>Branding Design</p>
                                                        <h3>Fashion Chair Design Brand</h3>
                                                    </Link>
                                                    <div className="slider-btn">
                                                        <Link to="/Portfolio" tabIndex={0}>
                                                            <i className="fa-sharp fa-light fa-arrow-up" />
                                                        </Link>
                                                    </div>
                                                </figcaption>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`tab-pane fade   ${tabMenu.e && "show active"}`} id="id-app" role="tabpanel" aria-labelledby="id-app" >
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <div className="grid-item family">
                                            <Link to="/Portfolio"></Link>
                                            <figure className="gallery-image">
                                                <Link to="/Portfolio">
                                                    <img src={Gallery4} alt="img" className="img" />
                                                </Link>
                                                <figcaption>
                                                    <Link to="/Portfolio"></Link>
                                                    <Link to="/Portfolio">
                                                        <p>Branding Design</p>
                                                        <h3>Fashion Chair Design Brand</h3>
                                                    </Link>
                                                    <div className="slider-btn">
                                                        <Link to="/Portfolio" tabIndex={0}>
                                                            <i className="fa-sharp fa-light fa-arrow-up" />
                                                        </Link>
                                                    </div>
                                                </figcaption>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`tab-pane fade   ${tabMenu.f && "show active"}`} id="id-marketing" role="tabpanel" aria-labelledby="id-marketing" >
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <div className="grid-item family">
                                            <Link to="/Portfolio"></Link>
                                            <figure className="gallery-image">
                                                <Link to="/Portfolio">
                                                    <img src={Gallery4} alt="img" className="img" />
                                                </Link>
                                                <figcaption>
                                                    <Link to="/Portfolio"></Link>
                                                    <Link to="/Portfolio">
                                                        <p>Branding Design</p>
                                                        <h3>Fashion Chair Design Brand</h3>
                                                    </Link>
                                                    <div className="slider-btn">
                                                        <Link to="/Portfolio" tabIndex={0}>
                                                            <i className="fa-sharp fa-light fa-arrow-up" />
                                                        </Link>
                                                    </div>
                                                </figcaption>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Gallery
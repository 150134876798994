import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../assets/img/logo/vachilogo.jpeg'

function Main() {

    const [scroll, setScroll] = useState(false);
    const [mobile, setMobile] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setScroll(true);
        } else {
            setScroll(false);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            const breakpoint = 1024;

            if (window.innerWidth <= breakpoint) {
                setMobile(true);
            } else {
                setMobile(false);
            }
        };

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };
    const closeMenu = () => {
        setMobileMenuOpen(false);
    };

    const handleMenuItemClick = () => {
        closeMenu();
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <>
            <header className="header-area header-two">
                <div className="menu-area">
                    <div className="container">
                        <Link to="/">
                            <img src={Logo} alt="logo" width='400px' length='400px'/>
                        </Link>
                        <div className="row align-items-left">
                            {/* <div className="col-xl-2 col-lg-2">
                                <div className="header-social">
                                    <span>
                                        <Link to="#" title="Facebook">
                                            <i className="fab fa-facebook-f" />
                                        </Link>
                                        <Link to="#" title="LinkedIn">
                                            <i className="fab fa-instagram" />
                                        </Link>
                                        <Link to="#" title="Twitter">
                                            <i className="fab fa-twitter" />
                                        </Link>
                                        <Link to="#" title="Twitter">
                                            <i className="fab fa-youtube" />
                                        </Link>
                                    </span>
                                </div>
                            </div> */}
                            <div className="col-xl-8 col-lg-8 text-center">
                                <div className="main-menu">
                                    <nav id="mobile-menu">
                                        <ul>
                                            <li className="has-sub">
                                                <Link to="/">Home</Link>
                                            </li>
                                            <li>
                                                <Link to="/service">Services</Link>
                                            </li>
                                            <li>
                                                <Link to="/pricing">Pricing</Link>
                                            </li>
                                            <li>
                                                <Link to="/aboutus">About Us</Link>
                                            </li>
                                            {/* <li className="has-sub">
                                                <Link to="#">Pages</Link>
                                                <ul>
                                                    <li>
                                                        <Link to="/service">Services</Link>
                                                    </li>
                                                    <li>
                                                        {" "}
                                                        <Link to="/servicedetails">Services Details</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/projects">Project</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/team">Our Team</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/teamdetails">Team Details</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/pricing">Pricing</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/faq">Faq</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/shop">Shop</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/shopdetails">Shop Details</Link>
                                                    </li>
                                                </ul>
                                            </li> */}
                                            {/* <li className="has-sub">
                                                <Link to="/blog">Blog</Link>
                                                <ul>
                                                    <li>
                                                        <Link to="/blog">Blog</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/blogdetails">Blog Details</Link>
                                                    </li>
                                                </ul>
                                            </li> */}
                                            <li>
                                                <Link to="/contact">Contact us</Link>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            {/* <div className="col-xl-2 col-lg-2 text-right d-none d-lg-block text-right text-xl-right">
                                <div className="header-cta">
                                    <ul>
                                        <li>
                                            <Link to="#">
                                                <i className="fa-thin fa-user" /> Sign In
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#">
                                                <i className="fa-thin fa-cart-shopping" /> My Cart
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div> */}
                            <div className="col-12">
                                <div className="mobile-menu" />
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {mobile && (

                <div className="mobile-menu mean-container">
                    <div className="mean-bar">
                        <Link to="#nav" onClick={toggleMobileMenu}
                            className={`meanmenu-reveal ${mobileMenuOpen ? 'meanclose' : ''}`}
                            style={{ right: 0, left: "auto", textAlign: "center", textIndent: 0, fontSize: 18 }} >
                            {mobileMenuOpen ? 'X' : <span><span><span></span></span></span>}
                        </Link>
                        <nav className="mean-nav">
                            <ul style={{ display: mobileMenuOpen ? 'block' : 'none' }}>
                                <li className={`has-sub ${handleMenuItemClick ? 'open' : ''}`}>
                                    <Link to="/" onClick={handleMenuItemClick}>Home</Link>
                                    <ul style={{ display: "none" }}>
                                        <li>
                                            <Link to="/">Home Page 01</Link>
                                        </li>
                                        <li>
                                            <Link to="/homepage2">Home Page 02</Link>
                                        </li>
                                        <li>
                                            <Link to="/homepage3">Home Page 03</Link>
                                        </li>
                                    </ul>
                                    <Link className="mean-expand" to="#" style={{ fontSize: 18 }} >
                                        +
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/aboutus">About Us</Link>
                                </li>
                                <li className="has-sub">
                                    <Link to="#">Pages</Link>
                                    <ul style={{ display: "none" }}>
                                        <li>
                                            <Link to="/service">Services</Link>
                                        </li>
                                        <li>
                                            {" "}
                                            <Link to="/servicedetails">Services Details</Link>
                                        </li>
                                        <li>
                                            <Link to="/projects">Project</Link>
                                        </li>
                                        <li>
                                            <Link to="/team">Our Team</Link>
                                        </li>
                                        <li>
                                            <Link to="/teamdetails">Team Details</Link>
                                        </li>
                                        <li>
                                            <Link to="/pricing">Pricing</Link>
                                        </li>
                                        <li>
                                            <Link to="/faq">Faq</Link>
                                        </li>
                                        <li>
                                            <Link to="/shop">Shop</Link>
                                        </li>
                                        <li>
                                            <Link to="/shopdetails">Shop Details</Link>
                                        </li>
                                    </ul>
                                    <Link className="mean-expand" to="#" style={{ fontSize: 18 }} >
                                        +
                                    </Link>
                                </li>
                                <li className="has-sub">
                                    <Link to="/blog">Blog</Link>
                                    <ul style={{ display: "none" }}>
                                        <li>
                                            <Link to="/blog">Blog</Link>
                                        </li>
                                        <li>
                                            <Link to="/blogdetails">Blog Details</Link>
                                        </li>
                                    </ul>
                                    <Link className="mean-expand" to="#" style={{ fontSize: 18 }} >
                                        +
                                    </Link>
                                </li>
                                <li className="mean-last">
                                    <Link to="/contact">Contact</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            )
            }
        </>
    )
}

export default Main
import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/img/bg/cp-logo.png';

const pricingPlans = [
    {
        title: 'Basic Plan',
        price: '$1.96',
    },
    {
        title: 'Standard Plan',
        price: '$5.96',
    },
    {
        title: 'Premium Plan',
        price: '$9.96',
    },
];



const pricingData = [
    {
        title: 'Basic Plan',
        price: 'INR 79,999',
        description: 'Per Member Monthly Package 2024',
        features: ['Web application',
                   'Database Management',
                   'Basic UI UX',
                   'Customization',
                   'Data Migration',
                   'Mobile application',
                   'Analytics and insights'
                ]
    },
    {
        title: 'Standard Plan',
        price: 'INR 1,59,999',
        description: 'Per Member Monthly Package 2024',
        features: ['All features in Basic plan',
                   'Integration services',
                   'System performace',
                   'Automated testing',
                   'pipeline deployment',
                   'Cloud Architecture',
                   'Cloud deployment and integration'
                ]
    },
    {
        title: 'Premium Plan',
        price: 'Lets discuss',
        description: 'Contact us and have a discussion',
        features: ['All features in Basic and Standardplan',
                   'End to end solution design',
                   'Digital Marketing',
                   'Support and Maintanence',
                   'User Journey',
                   'Product Visualisation',
                   'Load and performance monitoring'
                ]
    },
];


function PricingPlan() {
    return (
        <>
            <section id="pricing" className="pricing-area pt-120 pb-60 fix p-relative">
                <div className="container">
                    <div className="row">
                    {pricingData.map((plan, index) => (
                            <div className="col-lg-4 col-md-6" key={index}>
                                <div className="pricing-box pricing-box2 mb-60">
                                    <div className="pricing-head">
                                        {/* <div className="month">
                                            <img src={CpLogo} alt="circle_right" />
                                        </div> */}
                                        <h3>{plan.title}</h3>
                                        <div className="price-count">
                                            <h2>{plan.price}</h2>
                                        </div>
                                        <p>{plan.description}</p>
                                        <hr />
                                    </div>
                                    <div className="pricing-body mt-20 mb-30 text-left">
                                        <ul>
                                            {plan.features.map((feature, index) => (
                                                <li key={index}>{feature}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="pricing-btn mb-30">
                                        <Link to="/contact" className="btn ss-btn">
                                            Sign Up Now <i className="fa-sharp fa-light fa-arrow-up" />
                                        </Link>
                                    </div>
                                    <div className="price-ntext">
                                        <i className="fa-thin fa-badge-check" />
                                        <p>Trusted by 6,000+ Companies Support Sponsor.</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}

export default PricingPlan;

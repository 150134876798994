import React from 'react'
import Productimg1 from '../../assets/img/bg/product-small-img1.png'
import Productimg2 from '../../assets/img/bg/product-small-img2.png'
import Logo from '../../assets/img/bg/p-logo.png'
import Icon1 from '../../assets/img/features/about-icon1.png'
import Icon2 from '../../assets/img/features/about-icon2.png'
import Icon3 from '../../assets/img/features/about-icon3.png'

function DetailsArea() {
    return (
        <>
            <div className="about-area5 about-p p-relative inner-blog">
                <div className="container pt-50 pb-90">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="service-detail">
                                <div className="content-box">
                                    <h2>Sketch Design</h2>
                                    <div className="two-column">
                                        <div className="row">
                                            <div className="col-xl-6">
                                                <p>
                                                    Ultrices nulla euvenenatis dui. Praesent luctus urna eu
                                                    dapibus pulvinar. Curabitur sed magna accumsan neque
                                                    fermentum laoreet. Nunc eros mi, rhoncus sed orci non,
                                                    luctus fringilla ligula. Mauris massa lacus, iaculis elend.
                                                </p>
                                            </div>
                                            <div className="col-xl-6">
                                                <p>
                                                    Accumsan neque fermentum laoreet. Ut nunc.Mauris massa
                                                    lacus, iaculis et eleifend placerat scelerisque risus.{" "}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="two-column">
                                        <div className="row">
                                            <div className="col-xl-6">
                                                <p>
                                                    Nunc eros mi, rhoncus sed orci non, luctus fringilla ligula.
                                                    Mauris massa lacus, iaculis elend placer scelerisque risus.
                                                    Maecenas sed tortor molestie, satis nibh sit amet dapibus
                                                    felis. Vivamus sed neque iaculis.
                                                </p>
                                            </div>
                                            <div className="col-xl-6">
                                                <p>
                                                    Maecenas sed tortor molestie, sagittis nibh am dapibus
                                                    felis. Vivamus sed neque iaculis, ultrices nulla Venenatis
                                                    dui. Praesent luctus urna eu dapibus pulvinar.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <p>
                                        Duis sed augue condimentum, blandit sapien in, accumsan eros.
                                        Curabitur sodales pulvinar libero et venenatis. Nullam eleifend
                                        risus a quam dictum auctor. Mauris at leo non dui euismod varius.
                                        Cras vel erat at est aliquam laoreet. Donec tincidunt, nunc eu
                                        gravida malesuada, tellus leo.
                                    </p>
                                    <div className="two-column mt-50 btb">
                                        <div className="row">
                                            <div className="image-column col-xl-3 col-lg-12 col-md-12">
                                                Clients:
                                                <h6>MD Towkib Tanvir (MG)</h6>
                                            </div>
                                            <div className="image-column col-xl-3 col-lg-12 col-md-12">
                                                Category:
                                                <h6>Business Consulting</h6>
                                            </div>
                                            <div className="image-column col-xl-3 col-lg-12 col-md-12">
                                                Timeframe:
                                                <h6>August 05, 2023</h6>
                                            </div>
                                            <div className="image-column col-xl-3 col-lg-12 col-md-12">
                                                Website Link:
                                                <h6>www.creativemela.com</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 ">
                            <div className="service-detail pt-50">
                                <div className="content-box">
                                    <div className="two-column">
                                        <div className="row">
                                            <div className="col-xl-6">
                                                <figure className="image">
                                                    <img src={Productimg1} alt="" />
                                                </figure>
                                            </div>
                                            <div className="col-xl-6">
                                                <figure className="image">
                                                    <img src={Productimg2} alt="" />
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                    <h3>Visual and typography hierarchy</h3>
                                    <p>
                                        Duis sed augue condimentum, blandit sapien in, accumsan eros.
                                        Curabitur sodales pulvinar libero et venenatis. Nullam eleifend
                                        risus a quam dictum auctor. Mauris at leo non dui euismod varius.
                                        Cras vel erat at est aliquam laoreet. Donec tincidunt, nunc eu
                                        gravida malesuada, tellus leo.
                                    </p>
                                    <p>
                                        Nunc eros mi, rhoncus sed orci non, luctus fringilla ligula.
                                        Mauris massa lacus, iaculis elend placer scelerisque risus.
                                        Maecenas sed tortor molestie, satis nibh sit amet dapibus felis.
                                        Vivamus sed neque iaculis.
                                    </p>
                                    <p>
                                        Ultrices nulla euvenenatis dui. Praesent luctus urna eu dapibus
                                        pulvinar. Curabitur sed magna accumsan neque fermentum laoreet.
                                        Nunc eros mi, rhoncus sed orci non, luctus fringilla ligula.
                                        Mauris massa lacus, iaculis elend.
                                    </p>
                                    <div className="two-column mb-30">
                                        <div className="row">
                                            <div className="text-column col-xl-12 col-lg-12 col-md-12">
                                                <ul>
                                                    <li>Will you provide website layout about design</li>
                                                    <li>Branding With Business Agency</li>
                                                    <li>Why should I work with an agency over a freelancer</li>
                                                    <li>How much do you charge for branding</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="two-column2 mt-50 mb-30">
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-12 col-md-12">
                                                <img src={Logo} alt="" />
                                            </div>
                                            <div className="col-xl-4 col-lg-12 col-md-12">
                                                <strong>Regular</strong>
                                                <strong>Medium</strong>
                                                <strong>SemiBold</strong>
                                                <strong>Blod</strong>
                                            </div>
                                            <div className="col-xl-4 col-lg-12 col-md-12">
                                                <p>This Is Text Message</p>
                                                <p>Medium Typography</p>
                                                <p>Just Amazing</p>
                                                <p>Awesome</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 mt-50">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <div className="service-detail">
                                        <div className="content-box">
                                            <h3>Business consultancy and Meika to Join Our Work.</h3>
                                            <p>
                                                Duis sed augue condimentum, blandit sapien in, accumsan eros.
                                                Curabitur sodales pulvinar libero et venenatis. Nullam
                                                eleifend risus a quam dictum auctor. Mauris at leo non dui
                                                euismod varius. Cras vel erat at est aliquam laoreet. Donec
                                                tincidunt, nunc eu gravida malesuada, tellus leo.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <div className="service-detail">
                                        <div className="content-box">
                                            <h3>
                                                I Have a fondness for clean based in San Francisco. Currently
                                                a freelancer. You can see my hobbies on and some photos on me
                                                too.
                                            </h3>
                                            <p>
                                                I have a fondness for clean designs, subtle interactions and
                                                delightful user experiences. If you are interested you can
                                                check out some of work on Pellentesque sed justo malesuada,
                                                interdum dolor vitae, fringilla lorem.
                                            </p>
                                            <div className="about-content2 mt-50 mb-50">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="about-sbox">
                                                            <div className="icon">
                                                                <img src={Icon1} alt="img" />
                                                            </div>
                                                            <h6>UX/UI Design</h6>
                                                            <p>Pellentesqu sit amet urna justo.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="about-sbox active">
                                                            <div className="icon">
                                                                <img src={Icon2} alt="img" />
                                                            </div>
                                                            <h6>Branding</h6>
                                                            <p>Pellentesqu sit amet urna justo.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="about-sbox">
                                                            <div className="icon">
                                                                <img src={Icon3} alt="img" />
                                                            </div>
                                                            <h6>Photography</h6>
                                                            <p>Pellentesqu sit amet urna justo.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <p>
                                                I have a fondness for clean designs, subtle interactions and
                                                delightful user experiences. If you are interested you can
                                                check out some of work on Pellentesque sed justo malesuada,
                                                interdum dolor vitae, fringilla lorem.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default DetailsArea
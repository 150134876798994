import './App.css';
import { BrowserRouter as Router } from "react-router-dom"
import Routing from './routes';

import '../src/assets/css/bootstrap.min.css'
import '../src/assets/css/animate.min.css'
import '../src/assets/css/magnific-popup.css'
import '../src/assets/fontawesome/css/all.min.css'
import '../src/assets/css/dripicons.css'
import '../src/assets/css/slick.css'
import '../src/assets/css/meanmenu.css'
import '../src/assets/css/default.css'
import '../src/assets/css/style.css'
import '../src/assets/css/responsive.css'


function App() {
  return (
    <>
      <Router >
        <Routing />
      </Router >
    </>
  );
}

export default App;

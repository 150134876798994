import React from 'react';
import About03 from '../../assets/img/features/about_img_03.png';
import SubLogo from '../../assets/img/features/about-icon4.png';
import Icon1 from '../../assets/img/features/about-icon1.png'
import Icon2 from '../../assets/img/features/about-icon2.png'
import Icon3 from '../../assets/img/features/about-icon3.png'

const aboutContent = {
    title: "We Create Software Solutions for AI Creative Agency.",
    description:
        "We have best developers with Architect, Designer, Marketing and Business modelers and with many other diverse skillset associates. SMEs from multiple domain resolve critical functional challenges. ",
};

const aboutSboxes = [
    {
        icon: Icon1,
        title: 'Ideation and UX/UI Design',
        description: 'High end wareframe design and conceptualization.',
    },
    {
        icon: Icon2,
        title: 'Software architecture',
        description: 'Scalable, reliable, reusable architecture',
    },
    {
        icon: Icon3,
        title: 'Cloud based approaches',
        description: 'Cloud computing migration and hosting.',
    },
];

function About() {
    return (
        <>
            <section id="about" className="about-area about-p pb-120 p-relative fix">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="s-about-img p-relative wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s">
                                <img src={About03} alt="img" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="about-content s-about-content wow fadeInRight animated" data-animation="fadeInRight" data-delay=".4s">
                                <div className="about-title second-title pb-25">
                                    <h5>
                                        <span className="line">
                                            {" "}
                                            <img src={SubLogo} alt="circle_right" />
                                        </span>{" "}
                                        About Us Vachilabs
                                    </h5>
                                    <h2>{aboutContent.title}</h2>
                                </div>
                                <p>{aboutContent.description}</p>
                                <div className="about-content2 mt-50">
                                    <div className="row">
                                        {aboutSboxes.map((sbox, index) => (
                                            <div className="col-md-4" key={index}>
                                                <div className={`about-sbox ${index === 1 ? 'active' : ''}`}>
                                                    <div className="icon">
                                                        <img src={sbox.icon} alt="img" />
                                                    </div>
                                                    <h6>{sbox.title}</h6>
                                                    <p>{sbox.description}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default About;

import React from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import PortfolioImg from '../MenPortfolio/PortfolioImg'
import DetailsArea from '../MenPortfolio/DetailsArea'
import Footer from '../Footer/Main'

function Main() {
    return (
        <>
            <Header />
            <main>
                <Breadcrumb mainheading={"Protfolio Deatils"} sub={"Home"} title={"Services Details"} />
                <PortfolioImg />
                <DetailsArea />
            </main>
            <Footer />

        </>
    )
}

export default Main
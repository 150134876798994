import React from 'react'
import Mapimg from '../../assets/img/bg/map.png'

function Map() {
    return (
        <>
            <div className="map pt-120 fix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* <a href="https://www.google.com/maps/place/23%C2%B010'47.1%22N+77%C2%B025'18.8%22E/@23.1794462,77.4207581,18z/data=!4m4!3m3!8m2!3d23.17975!4d77.4218889?entry=ttu"> */}
                            <img src={Mapimg}  alt="logo" onClick={()=>{window.open("https://www.google.com/maps/place/23%C2%B010'47.1%22N+77%C2%B025'18.8%22E/@23.1794462,77.4207581,18z/data=!4m4!3m3!8m2!3d23.17975!4d77.4218889?entry=ttu")}} />
                            {/* </a> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Map
import React from 'react';
import { Link } from 'react-router-dom';
import Bg1 from '../../assets/img/bg/cta_bg02.png';

const ctaData = [
    {
        title: 'Get Started and grow your business now.',
        buttonText: 'Get Started For Free',
        linkTo: '/aboutus',
    },
];

function Cta() {
    return (
        <>
            {ctaData.map((cta, index) => (
                <section className="cta-area cta-bg2" key={index}>
                    <div className="container pt-75 pb-75 pl-60 pr-60"
                        style={{ backgroundImage: `url(${Bg1})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center', }} >
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="section-title cta-title wow fadeInLeft animated" data-animation="fadeInDown animated" data-delay=".2s" >
                                    <h2>{cta.title}</h2>
                                </div>
                            </div>
                            <div className="col-lg-4 text-right">
                                <div className="cta-btn s-cta-btn wow fadeInRight animated mt-30" data-animation="fadeInDown animated" data-delay=".2s" >
                                    <Link to={cta.linkTo} className="btn ss-btn smoth-scroll">
                                        {cta.buttonText} <i className="fa-sharp fa-light fa-arrow-up" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            ))}
        </>
    );
}

export default Cta;

import React from 'react'
import { Link } from 'react-router-dom'
import BreadBg from '../../assets/img/bg/bdrc-bg.png'

function Breadcrumb({ sub, title, mainheading }) {
    return (
        <>
            <section className="breadcrumb-area d-flex align-items-center" style={{ backgroundImage: `url(${BreadBg})` }} >
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-12 col-lg-12">
                            <div className="breadcrumb-wrap text-center"> 
                                <div className="breadcrumb-title">
                                    <h2>{mainheading}</h2>
                                    <div className="breadcrumb-wrap">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to="/">{sub}</Link>
                                                </li>
                                                <li className="breadcrumb-item active" aria-current="page">{title}
                                                </li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Breadcrumb
import React from 'react'
import { Link } from 'react-router-dom'
import Icon1 from '../../assets/img/icon/sr-icon1.png'
import Icon1hover from '../../assets/img/icon/sr-icon1-hover.png'
import Bg1 from '../../assets/img/bg/sr-h-bg.png'
import Icon2 from '../../assets/img/icon/sr-icon2.png'
import Icon2hover from '../../assets/img/icon/sr-icon2-hover.png'
import Icon3 from '../../assets/img/icon/sr-icon3.png'
import Icon3hover from '../../assets/img/icon/sr-icon3-hover.png'
import Icon4 from '../../assets/img/icon/sr-icon4.png'
import Icon4hover from '../../assets/img/icon/sr-icon4-hover.png'
import Icon9 from '../../assets/img/icon/sr-icon9.png'
import Icon9hover from '../../assets/img/icon/sr-icon9-hover.png'
import Icon10 from '../../assets/img/icon/sr-icon10.png'
import Icon10hover from '../../assets/img/icon/sr-icon10-hover.png'
import Icon11 from '../../assets/img/icon/sr-icon11.png'
import Icon11hover from '../../assets/img/icon/sr-icon11-hover.png'
import Icon12 from '../../assets/img/icon/sr-icon12.png'
import Icon12hover from '../../assets/img/icon/sr-icon12-hover.png'
import service_details from '../../services_details.json'
import icon33 from '../../assets/img/bg/new/service_product_engineering.png'

function Abtservice() {
    return (
        <>
            <section id="services" className="services-area services-bg  p-relative fix pt-120 pb-90" >
                <div className="container">
                    <div className="row">
                        {/* <div className="col-lg-3 col-md-6">
                            <div className="s-single-services mb-30 wow fadeInUp animated"
                                data-animation="fadeInDown animated" data-delay=".2s" >
                                <div className="services-icon">
                                    <img src={Icon1} alt="icon" />
                                    <img src={Icon1hover} alt="img" className="hover" />
                                </div>
                                <div className="second-services-content">
                                    <h5>
                                        <Link to="/servicedetails">Web Design</Link>
                                    </h5>
                                    <p>
                                        Pellentesque sit amet urna justo. Fusce velit nibh commo iaculis
                                        vestibulum condimentum.
                                    </p>
                                    <Link className="btn" to="/servicedetails">
                                        Read More <i className="fa-sharp fa-light fa-arrow-up" />
                                    </Link>
                                </div>
                                <div className="sr-h-bg">
                                    <img src={Bg1} alt="icon" />
                                </div>
                            </div>
                        </div> */}
 


                        {
                        Object.keys(service_details).map((key, ind) => {
                            return(

                                <div className="col-lg-4 col-md-6">
                                    <div className="s-single-services mb-30 wow fadeInUp animated"
                                        data-animation="fadeInDown animated" data-delay=".2s" >
                                        <div className="services-icon">
                                            <img src={service_details[key].icon} alt="icon" />
                                            <img src={service_details[key].hovericon} alt="img" className="hover" />
                                        </div>
                                        <div className="second-services-content">
                                            <h5>
                                                <Link to={{ pathname: `/servicedetails/${key}`, state: key}}>{service_details[key].service_title}</Link>
                                            </h5>
                                            <p>
                                                {service_details[key].general_description}
                                            </p>
                                            <Link className="btn" to={{ pathname: `/servicedetails/${key}`, state: key}}>
                                                Read More <i className="fa-sharp fa-light fa-arrow-up" />
                                            </Link>
                                        </div>
                                        <div className="sr-h-bg">
                                            <img src={Bg1} alt="icon" />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }


                    </div>
                </div>
            </section>
        </>
    )
}

export default Abtservice